import { Component, OnInit } from '@angular/core';
import { Auth0Service } from '../../../../core/services/auth0/auth0.service';

@Component({
  selector: 'app-connexion-sopedi',
  templateUrl: './connexion-sopedi.component.html',
  styleUrls: ['./connexion-sopedi.component.scss']
})
export class ConnexionSopediComponent implements OnInit {
  checkBoxes = [false, false, false, false];
  result1; result2; result3; result4;
  constructor(private auth0Service: Auth0Service) { }

  ngOnInit() {
  }
  checkBox(i) {
    this.checkBoxes[i] = !this.checkBoxes[i];
  }
  onAuth() {
    this.auth0Service.authMagento().subscribe(res => {
      this.result1 = res;
      console.log('Résultat authMagento : ', res);
    });
  }
  onGetToken() {
    this.auth0Service.getToken().subscribe(res => {
      this.result2 = res;
      console.log('Résultat get token :', res);
    });
  }

  onSendCartes() {
    this.auth0Service.sendCartes({}, {}).subscribe(res => {
      console.log('Résultat send cartes :', res);
      this.result3 = res;
    });
  }

  getUrlRedirection() {
    this.auth0Service.getUrlRedirection().subscribe(res => {
      this.result4 = res;
      console.log('Résultat get url redirection :', res);
    });
  }


}
