import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/authentification/auth.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AdminGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        const email = this.authService.getEmail();
        return this.checkIfAdmin(email);

    }

    canLoad(): (boolean | Observable<boolean> | Promise<boolean>) {
        const email = this.authService.getEmail();
        return this.checkIfAdmin(email);
    }


    checkIfAdmin(email: string): Observable<boolean> {
        return this.authService.getSecureStatus(email).pipe(map(
            res => {
                if (res.status === 'Admin' && res.universe !== 'marge') {
                    return true;
                } else {
                    this.router.navigate(['/accueil']);
                    return false;
                }
            }
        ));
    }

}

