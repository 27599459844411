// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  preprod: false,
  // LOCAL
  //urlApi: 'http://localhost:3001/api/',
  //urlInMail: 'http://localhost:4200/',
  //baseUrlBack: 'http://localhost:3001'



  //baseUrlBack: 'https://backpreprod.lesrecettesquifontrecette.com',
  //urlApi: 'https://backpreprod.lesrecettesquifontrecette.com/api/',
  //urlInMail: 'https://backpreprod.lesrecettesquifontrecette.com/',

  // DEV
  // urlApi: 'https://backdev.lesrecettesquifontrecette.com/node/api/',
  // urlInMail: 'https://dev.lesrecettesquifontrecette.com/#/',

  // PROD OLD
  // urlApi: 'https://backprod.lesrecettesquifontrecette.com/node/api/',
  // urlInMail: 'https://prod.lesrecettesquifontrecette.com/#/

  // PRODUCTION
  baseUrlBack: 'https://back.lesrecettesquifontrecette.com',
  urlApi: 'https://back.lesrecettesquifontrecette.com/api/',
  urlInMail: 'https://lesrecettesquifontrecette.com/',



};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
