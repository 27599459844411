import { BoissonsService } from './../boissons/boissons.service';
import { CocktailsService } from './../cocktails/cocktails.service';
import { RecipesService } from './../recettes/recipes.service';
import { AuthService } from 'src/app/core/authentification/auth.service';
import { Router } from '@angular/router';
import { map, tap, switchMap, filter, take, catchError } from 'rxjs/operators';
import { Card } from './../../../../shared/models/card.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of, forkJoin, Observable } from 'rxjs';

import * as _ from 'lodash';
import { CardValidationService } from './card-validation.service';
import { containsObject, deepSearch, searchRecipe } from '../../helper/objectHelper';
import { HttpClient } from '@angular/common/http';
import { CardHttpService } from './card-http.service';
import {CardConfig} from '../../../../shared/models/card-config.model';


@Injectable({
  providedIn: 'root'
})
export class CartesService extends CardValidationService {
  public modeConstruction;

  cardFoundSubject = new BehaviorSubject(false)

  private baseConstructionCard = {
    idOwner: 'null',
    englishVersion: false,
    title: '',
    toPrint: false,
    type: '',
    blocs: []
  };

  private listOfAllCards: Card[] = [];
  private listOfAllCardsSubject = new BehaviorSubject<Card[]>(null);
  private listOfCardsSubject = new BehaviorSubject<Card[]>(null); // card brouillon
  private listOfCardsToSendSubject = new BehaviorSubject<Card[]>(null); // card to print
  private constructionCardSubject = new BehaviorSubject(_.cloneDeep(this.baseConstructionCard));


  private emptyJsonCard = new BehaviorSubject(null);

  public cardChange = false;
  public cardChangeSubject = new BehaviorSubject(false);
  currentType; // not sure this is trully used but can't remove it at the moment

  cardCatalog = {
    'type': '', // type is legacy, should disapear with time (require a db update)
    'cardType': '',
    'typeHotel': '', // not used for now, will it be usefull ?
    'recipes': [],
    'cocktails': [],
    'drinks': []
  };
  cardCatalogSubject = new BehaviorSubject({ ...this.cardCatalog });

  constructor(
    private router: Router,
    private authService: AuthService,
    private recipeService: RecipesService,
    private cocktailService: CocktailsService,
    private drinkService: BoissonsService,
    private cardHttpService: CardHttpService
  ) {
    super();
  }

  /**
  * CALL API
  */
  getJsonCard<Card>(cardType, subUniverse) {
    return this.cardHttpService.getJsonCard(cardType, subUniverse).pipe(map((res: any) => {
      if (res.card) {
        const card = res.card
        delete res.card.date_created
        card.idConfigCard = card._id
        delete card._id
        card.hotelBrand = subUniverse;
        this.cardFoundSubject.next(true)
        return card
      }
      return res.card
    }), catchError(err => {
      this.cardFoundSubject.next(false)
      return new Observable()
    }))
  }

  postCard(title, englishVersion) {
    const listSaved = JSON.parse(localStorage.getItem('construction-card'));
    const newListe = { ...listSaved, title, englishVersion };
    return this.cardHttpService.postCard(newListe).pipe(tap((res) => {
      // console.log('resultat du post', res);
      this.constructionCardSubject.next(_.cloneDeep(newListe));
      localStorage.setItem('construction-card', JSON.stringify(newListe));
    }));
  }

  getCardById(id) {
    return this.cardHttpService.getCardById(id).pipe(tap(res => {
      if(res) {
        this.setModelCard(res.card);
        this.cardFoundSubject.next(true)
        this.emptyJsonCard.next([...res.card.blocs]);
      }
    }));
  }

  getCardsModelsByType(type) {
    return this.cardHttpService.getCardsModelsByType(type)
  }

  getMyCards() {
    return this.cardHttpService.getMyCards().pipe(tap(res => {
      this.listOfAllCards = res.cards;
      this.listOfAllCardsSubject.next(res.cards);
      this.listOfCardsSubject.next(res.cards.filter(u => u.toPrint === false));
      this.listOfCardsToSendSubject.next(res.cards.filter(u => u.toPrint === true));
    }), map(res => res.cards));
  }

  switchListCard(idCard, toPrint) {
    return this.cardHttpService.switchListCard(idCard, toPrint).pipe(
      tap(res => {
        this.listOfAllCards.find(c => c._id === idCard).toPrint = toPrint;
        this.listOfCardsSubject.next([...this.listOfAllCards].filter(u => u.toPrint === false));
        this.listOfCardsToSendSubject.next([...this.listOfAllCards].filter(u => u.toPrint === true));
      })
    );
  }

  deleteCardById(cardID) {
    return this.cardHttpService.deleteCardById(cardID).pipe(tap(() => {
      const index = this.listOfAllCards.findIndex(c => c._id === cardID);
      this.listOfAllCards.splice(index, 1);
      this.listOfCardsToSendSubject.next(this.listOfAllCards.filter(u => u.toPrint === true));
      this.listOfAllCardsSubject.next([...this.listOfAllCards]);
      this.listOfCardsSubject.next([...this.listOfAllCards].filter(u => u.toPrint === false));
    }));
  }

  switchDraftCard(idCard, isModelToUser) {
    return this.cardHttpService.switchDraftCard(idCard, isModelToUser).pipe(
      tap(res => {
        this.listOfAllCards.find(c => c._id === idCard).isModelToUser = isModelToUser;
        this.listOfAllCardsSubject.next([...this.listOfAllCards]);

      })
    );
  }

  patchCardById(idCard, title, englishVersion) {
    return this.cardHttpService.patchCardById(idCard, title, englishVersion).pipe(tap((res: any) => {
      this.constructionCardSubject.next(_.cloneDeep(res.card));
      localStorage.setItem('construction-card', JSON.stringify(res.card));
    }));
  }

  getAllUniverseCards() {
    return this.cardHttpService.getAllUniverseCards()
  }
  /**
   * END CALL API
   */

  /**
   * LISTENNER
   */
  getEmptyJsonCard() {
    return this.emptyJsonCard.asObservable();
  }
  getAllCardsListener() {
    return this.listOfAllCardsSubject.asObservable();
  }

  getListOfCardsListener() {
    return this.listOfCardsSubject.asObservable();
  }
  getListOfCardsToSendListener() {
    return this.listOfCardsToSendSubject.asObservable();
  }

  getConstructionCardListener() {
    return this.constructionCardSubject.asObservable();
  }

  getCardChangeListener() {
    return this.cardChangeSubject.asObservable();
  }

  getCardCatalogListener() {
    return this.cardCatalogSubject.asObservable();
  }

  getCardFoundStatus() {
    return this.cardFoundSubject.asObservable()
  }

  /**
  * END LISTENNER
  */

  /**
 * on create new card only.
 * @param cardtype
 */
  initCardFromJson(cardtype) {
    console.log('INIT CARD FROM JSON');
    // not the most beautiful but the less breaking change
    console.log(cardtype, localStorage.getItem('typeHotel'));
    this.getJsonCard(cardtype, localStorage.getItem('typeHotel')).pipe(take(1)).subscribe((card: any) => {
      this.emptyJsonCard.next([...card.blocs]); // need deep copy
      this.addCardToLocalStorage(card);
      this.cardFoundSubject.next(true)
    }, error => {
      console.log(error);

    })
  }

  /**
 * init card from json (edit mode)
 * @param type : restaurant, bar, room
 * @param mode : initialisation / updateCardType / keepCurrentType
 */
  initFromJSON(type, mode) {
    console.log('init from json');
    const typeHotel = localStorage.getItem('typeHotel');
    const tmpCard = JSON.parse(localStorage.getItem('construction-card'));
    this.currentType = type;
    this.getJsonCard(type, typeHotel).pipe(take(1)).subscribe((card: any) => {
      let blocs = [...card.blocs];
      // let blocs = JSON.parse(JSON.stringify(localStorage.getItem('construction-card')))
      /** if we don't change the card type then the blocs are not from the json def file anymore, but from the localStorage */
      if (mode === 'keepCurrentType') {
        blocs = [...tmpCard.blocs];
      }
      if (mode === 'updateCardType') {
        tmpCard.title = '';
        delete tmpCard._id;
        delete tmpCard.isModelToUser;
        delete tmpCard.fromAdmin;
        delete tmpCard.isFull;
        delete tmpCard.hotelBrand;
        delete tmpCard.typeHotel;
      }
      let newCard;
      newCard = { ...tmpCard, ...card, blocs: blocs, type, typeHotel };

      //Gros doutes la dessus
      this.emptyJsonCard.next([..._.cloneDeep(card.blocs)]); // need deep copy. could use lodash. indeed


      localStorage.setItem('construction-card', JSON.stringify(newCard));
      this.constructionCardSubject.next(newCard);
    })
  }
  addFreeRecipeInCart(recipeToAdd, type) {
    const id = '_' + Math.random().toString(36).substr(2, 9); // ??
    const card = JSON.parse(localStorage.getItem('construction-card'));
    recipeToAdd = { _id: id, ...recipeToAdd, freeRecipe: true, indexCat: type.indexCat, indexBloc: type.indexBloc };
    const bloc = card.blocs[type.indexBloc];
    // ajout pour prendre en compte le min max only
    if(type.minmax_only){
      if (type.indexCat !== null && this.totalBlocRecipe(bloc) < bloc.max) {
        card.blocs[type.indexBloc].categories[type.indexCat].recettes.push({...recipeToAdd});
      } else if (type.indexBloc >= 0 && this.totalBlocRecipe(bloc) < bloc.max) {
        card.blocs[type.indexBloc].recettes.push({...recipeToAdd});
      }
    }else {
      if (!('rl_fa' in type)) {
        if (type.indexCat !== null && this.totalBlocFreeRecipe(bloc) < bloc.rl_fa) {
          card.blocs[type.indexBloc].categories[type.indexCat].recettes.push({...recipeToAdd});
        } else if (type.indexBloc >= 0 && this.totalBlocFreeRecipe(bloc) < bloc.rl_fa) {
          card.blocs[type.indexBloc].recettes.push({...recipeToAdd});
        }
      }
      if (type.indexCat !== null && this.totalFreeRecipe(bloc.categories[type.indexCat].recettes) < type.rl_fa) {
        card.blocs[type.indexBloc].categories[type.indexCat].recettes.push({...recipeToAdd});
      } else if (type.indexBloc >= 0 && this.totalFreeRecipe(card.blocs[type.indexBloc].recettes) < type.rl_fa) {
        card.blocs[type.indexBloc].recettes.push({...recipeToAdd});
      }
    }
    this.constructionCardSubject.next(card);
    localStorage.setItem('construction-card', JSON.stringify(card));
    if (type.indexCat !== null) {
      this.isFullCategorieByIndex(card.blocs[type.indexBloc].categories[type.indexCat], 'recettes');
      this.isMinRequiredCatByIndex(card.blocs[type.indexBloc].categories[type.indexCat], 'recettes');
    }
    this.isFullBlocByIndex(bloc, 'recettes');
    this.isMinRequiredBlocByIndex(bloc, 'recettes');
    return card;
  }

  editFreeRecipeInCart(recipe) {
    const card = JSON.parse(localStorage.getItem('construction-card'));
    let oldRecipe;
    if ('indexCat' in recipe && recipe.indexCat !== null) {
      oldRecipe = card.blocs[recipe.indexBloc].categories[recipe.indexCat].recettes.find(r => r._id === recipe._id);
      const index = card.blocs[recipe.indexBloc].categories[recipe.indexCat].recettes.findIndex(r => r._id === recipe._id);
      oldRecipe = { ...oldRecipe, ...recipe };
      card.blocs[recipe.indexBloc].categories[recipe.indexCat].recettes[index] = oldRecipe;
    } else {
      oldRecipe = card.blocs[recipe.indexBloc].recettes.find(r => r._id === recipe._id);
      const index = card.blocs[recipe.indexBloc].recettes.findIndex(r => r._id === recipe._id);
      oldRecipe = { ...oldRecipe, ...recipe };
      card.blocs[recipe.indexBloc].recettes[index] = oldRecipe;
    }
    this.constructionCardSubject.next(card);
    localStorage.setItem('construction-card', JSON.stringify(card));
  }

  updatePriceOfRecipe(newPrice, recipe) {
    const listLocale = JSON.parse(localStorage.getItem('construction-card'));
    const recipeFound = searchRecipe(listLocale.blocs, recipe);
    recipeFound.displayPrice = newPrice;
    localStorage.setItem('construction-card', JSON.stringify(listLocale));

    this.constructionCardSubject.next(listLocale);
  }

  updateConstructionCard() {
    const listLocale = JSON.parse(localStorage.getItem('construction-card'));
    this.constructionCardSubject.next(listLocale);
  }

  // TO DEBUG. MIGHT NOT WORK AS EXPECTED // has been working for months
  updatePriceOfDrink(recipe) {
    const listLocale = JSON.parse(localStorage.getItem('construction-card'));
    const recipeFound = searchRecipe(listLocale.blocs, recipe);
    const newPricing = { ...recipe }.pricing;
    recipeFound.pricing = [...newPricing];

    localStorage.setItem('construction-card', JSON.stringify(listLocale));
    this.constructionCardSubject.next(listLocale);
  }

  areSlugsFromBlocsInCardState(blocs, cardState) {
    const onlySlugCardState = Object.keys(cardState)
    const onlySlugBlocs = blocs.map(b => b.slug)
    // flat => remettre tout a la même profondeur , filter(e => e) = supprimer les null du tableaux dû au ternaire
    const onlySlugCat = blocs.map(b => b.categories ? b.categories.map(c => c.slug) : null).flat().filter(e => e);

    let isValid = false;

    // isValid = true si tous les slugs des blocs et catégories sont dans le card state
    isValid = (onlySlugBlocs.every(b => onlySlugCardState.includes(b)) &&
      onlySlugCat.every(c => onlySlugCardState.includes(c)))
    return isValid;
  }

  updateUniqueDisplayPrice(blocSlug, catSlug, newPrice) {
    const listLocale = JSON.parse(localStorage.getItem('construction-card'));
    const blocs = listLocale.blocs;
    if (this.areSlugsFromBlocsInCardState(blocs, this.cardState)) {
      if (catSlug !== '') {
        const category = blocs.find(b => b.slug === blocSlug).categories.find(c => c.slug === catSlug);
        category.displayPrice = newPrice;
        if (this.cardState[catSlug] === undefined) {
          console.log('cant find display price of ', catSlug, 'in', this.cardState)
        }
        this.cardState[catSlug].displayPrice = newPrice;
      } else {
        const bloc = blocs.find(b => b.slug === blocSlug);
        bloc.displayPrice = newPrice;
        this.cardState[blocSlug].displayPrice = newPrice;
      }
      this.cardStateSubject.next({ ...this.cardState });

      localStorage.setItem('construction-card', JSON.stringify(listLocale));
    }

  }

  addElmtInLocalList(elmt, init = false) {
    const card = JSON.parse(localStorage.getItem('construction-card'));
    const typeHotel = localStorage.getItem('typeHotel');
    const config = elmt.cardConfig.find(c =>
      c.cardType === card.cardType && c.subuniverse.some(x => x === typeHotel) === true
    );
    if (!config) {
      console.log('elmt not in this card', elmt);
      return;
    }
    const blocKey = config.bloc;
    const categoryKey = config.category;
    const simplifiedElmt = _.omit(elmt, ['equipSpec', 'ingredients',
      'difficulty', 'time', 'textBonus', 'hdImagePath']);

    elmt = simplifiedElmt;
    const blocToModify = card.blocs.find(el => el.slug === blocKey);
    if (!blocToModify || blocToModify === undefined) {
      // console.log('c\'est pas dans le json !');
      return;
    }
    const blocIndex = card.blocs.findIndex(el => el.slug === blocKey);
    const categoriesToChange = blocToModify.categories;
    // tslint:disable-next-line: max-line-length
    const categoryToModify = categoriesToChange !== undefined && (categoryKey !== '' || categoryKey !== undefined) ? categoriesToChange.find(c => c.slug === categoryKey) : undefined;
    const categoryIndex = categoryToModify ? categoriesToChange.findIndex(c => c.slug === categoryKey) : undefined;
    // console.log(config, blocKey, categoryKey, categoriesToChange, blocToModify, categoryToModify);

    if ((categoryKey === undefined || categoryKey === '') && 'recettes' in blocToModify && !containsObject(elmt, blocToModify.recettes)) {
      // add to bloc
      // tslint:disable-next-line:max-line-length
      blocToModify.cat_obl === this.totalBlocCatalogRecipe(blocToModify.recettes) ? console.log('full blocs', blocToModify.slug) : blocToModify.recettes.push(elmt);

      // tslint:disable-next-line: max-line-length
    } else if ((categoryKey !== undefined && categoryKey !== '') && !containsObject(elmt, categoryToModify.recettes)) { // add to category
      if ('cat_obl' in categoryToModify) {
        // tslint:disable-next-line:max-line-length
        categoryToModify.cat_obl === this.totalCatalogRecipe(categoryToModify.recettes) ? console.log('full cat', categoryToModify.slug) : categoryToModify.recettes.push(elmt);
      } else {
        // tslint:disable-next-line:max-line-length
        this.totalBlocCatalogRecipe(blocToModify) === blocToModify.cat_obl ? console.log('full blocs', blocToModify.slug) : categoryToModify.recettes.push(elmt);
      }

    }
    localStorage.setItem('construction-card', JSON.stringify(card));
    if (!init) {
      if (categoryIndex >= 0) { // otherwise 0 is false
        this.isFullCategorieByIndex(categoryToModify, 'recettes');
        this.isMinRequiredCatByIndex(categoryToModify, 'recettes');
      }
      this.isFullBlocByIndex(blocToModify, 'recettes');
      this.isMinRequiredBlocByIndex(blocToModify, 'recettes');
    }
    return card;
  }

  /*
  Thomas 12/02/2021 : remove novotel filter in the pipe
  */
  addElmtInCardRecipes(elmt) {
    // This also need to check if number of recipe is full or not // how to english today.
    const listLocale = this.addElmtInLocalList(elmt);
    return this.authService.getCurrentUniverseListener().pipe(
      switchMap(() => this.authService.getPriceModeListener()),
      tap(priceMode => {
        if (elmt.pricing) {
          elmt.pricing.forEach(el => {
            el['displayPrice'] = (priceMode === 'premium') ? el.premiumPrice : el.price;
          });
        } else {
          elmt.displayPrice = (priceMode === 'premium') ? elmt.premiumPrice : elmt.price;
        }
        this.constructionCardSubject.next(listLocale);
        localStorage.setItem('construction-card', JSON.stringify(listLocale));
      })
    );
  }

  resetAndNewType(type) {
    this.currentType = type;
    this.resetConstructionCard();
    this.initCardFromJson(type);
    this.initCardMust(type).subscribe(); // OUPSI
  }


  /**
   * Put a card in localstorage
   * @param card the card
   */
  addCardToLocalStorage(card) {
    localStorage.setItem('construction-card', JSON.stringify(card));
  }
  /**
   * Get the card in local storage
   */
  getCardFromLocalStorage() {
    return JSON.parse(localStorage.getItem('construction-card'));
  }



  /**
   * va chercher les recettes, boissons et cocktails lié à la carte et ajoute les must
   * à la carte de l'utilisateur si must = true
   * @param type type de carte (à l'avenir utiliser le idCardConfig)
   * @param must si true, ajoute les musts à la carte
   */
  initCardMust(type, must = true) {
    console.log('INIT MUST CARD');
    const typeHotel = localStorage.getItem('typeHotel');
    const addMust = (res) => {
      res.forEach(element => {
        const config = element.cardConfig.find(el =>
          el.cardType === type && el.subuniverse.some(x => x === typeHotel) === true
        );
        if (config && config.must && must) {
          this.addElmtInLocalList(element);
        }
      });
    }
    return forkJoin([
      this.recipeService.getRecipesByCardType(type).pipe(tap(res => {
        this.cardCatalog.recipes = res;
        if (!must) return
        addMust(res)
      })),
      this.cocktailService.getCocktailsByCardType(type).pipe(tap(res => {
        this.cardCatalog.cocktails = res;
        if (!must) return
        addMust(res)
      })),
      this.drinkService.getDrinksByCardType(type).pipe(tap(res => {
        this.cardCatalog.drinks = res;
        if (!must) return
        addMust(res)
      })),
    ]).pipe(
      tap(res => {
        // console.log(res);
        this.cardCatalog.type = type; // legacy code
        this.cardCatalog.cardType = type;
        this.cardCatalogSubject.next({ ...this.cardCatalog });
        if (!must) return
        this.constructionCardSubject.next(JSON.parse(localStorage.getItem('construction-card')));
      })
    );
  }

  // no must, no card, just the catalog (for edit purpose)
  initCardCatalog(type) {
    console.log('INIT CARD CATALOG (no must)', type);
    return this.initCardMust(type, false)
  }

  initConstructionCard() {
    if (localStorage.getItem('construction-card')) {
      this.constructionCardSubject.next(JSON.parse(localStorage.getItem('construction-card')));
    } else {
      localStorage.setItem('construction-card', JSON.stringify(this.baseConstructionCard));
      this.constructionCardSubject.next(this.baseConstructionCard);
    }
  }

  initCardFromStorage() {
    this.cardFoundSubject.next(true)
    this.constructionCardSubject.next(JSON.parse(localStorage.getItem('construction-card')));
  }

  resetConstructionCard() {
    console.log("resetConstructionCard");

    localStorage.setItem('construction-card', JSON.stringify(this.baseConstructionCard));
    this.constructionCardSubject.next(this.baseConstructionCard);
  }

  editMyCard(card) {
    localStorage.setItem('construction-card', JSON.stringify(card));
    this.cardChangeSubject.next(true);
    this.constructionCardSubject.next({ ...card });
    if(card.cardType){
      this.router.navigate(['/cartes', card.cardType, 'edit', card._id]);
    }else {
      this.router.navigate(['/cartes', card.type, 'edit', card._id]);
    }
  }

  deleteElmtFromCardRecipe(elmt) {
    console.log("delete elem from card :", elmt);

    const listLocale = JSON.parse(localStorage.getItem('construction-card'));

    if ('freeRecipe' in elmt) {
      console.log('je delete un free Recipe');
      const blocToModify = listLocale.blocs[elmt.indexBloc];
      if (elmt.indexCat !== null) {
        console.log('je delete un free Recipe - Categorie');
        const categoryToModify = blocToModify.categories[elmt.indexCat];
        console.log(categoryToModify);
        categoryToModify.recettes = categoryToModify.recettes.filter(r => r._id !== elmt._id);
        //semble non correct, il faudrait ré-évaluer isFull ?
        this.cardState[categoryToModify.slug].isFull = false;
        //Add Thomas to fix isMinRequires after delete
        console.log('avant modif : ', this.cardState[categoryToModify.slug].isMinRequired);
        this.cardState[categoryToModify.slug].isMinRequired = this.isMinRequiredCatByIndex(categoryToModify);
        console.log('après modif : ', this.cardState[categoryToModify.slug].isMinRequired);
        //Commented by Thomas 1003 - semble de trop
        if (!('rl_fa' in categoryToModify)) {
          this.cardState[blocToModify.slug].totalFree = this.cardState[blocToModify.slug].totalFree - 1
        }
        //this.cardState[categoryToModify.slug].totalFree = this.cardState[categoryToModify.slug].totalFree - 1;
      } else {
        console.log('je delete un free Recipe - Bloc');
        blocToModify.recettes = blocToModify.recettes.filter(r => r._id !== elmt._id);
        //semble non correct, il faudrait ré-évaluer isFull ?
        this.cardState[blocToModify.slug].isFull = false;
        //Add Thomas to fix isMinRequires after delete
        this.cardState[blocToModify.slug].isMinRequired = this.isMinRequiredBlocByIndex(blocToModify);
        //Commented by Thomas 1003 - semble de trop
        //this.cardState[blocToModify.slug].totalFree = this.cardState[blocToModify.slug].totalFree - 1;

      }
    } else {
      console.log('je delete un NON free Recipe : ', elmt.cardConfig);
      const typeHotel = localStorage.getItem('typeHotel');
      const config = elmt.cardConfig.find(c => (c.cardType === listLocale.type && c.subuniverse.some(x => x === typeHotel) === true) || (c.cardType === listLocale.cardType && c.subuniverse.some(x => x === typeHotel) === true));
      const blocToModify = listLocale.blocs.find(el => el.slug === config.bloc);
      if (config.bloc && config.category) {
        const categoryToModify = blocToModify.categories.find(c => c.slug === config.category);
        categoryToModify.recettes = categoryToModify.recettes.filter(r => r._id !== elmt._id);
        // WHAT ABOUT CAT LIKE ALCOOL ?????
        //semble non correct, il faudrait ré-évaluer isFull ?
        this.cardState[categoryToModify.slug].isFull = false;
        //Add Thomas to fix isMinRequires after delete
        this.cardState[categoryToModify.slug].isMinRequired = this.isMinRequiredCatByIndex(categoryToModify);
        //this.cardState[categoryToModify.slug].totalCatalog = this.cardState[categoryToModify.slug].totalCatalog - 1;
      } else {
        blocToModify.recettes = blocToModify.recettes.filter(r => r._id !== elmt._id);
        //semble non correct, il faudrait ré-évaluer isFull ?
        this.cardState[blocToModify.slug].isFull = false;
        this.cardState[blocToModify.slug].isMinRequired = this.isMinRequiredBlocByIndex(blocToModify);
        //this.cardState[blocToModify.slug].totalCatalog = this.cardState[blocToModify.slug].totalCatalog - 1;
      }
    }
    this.cardStateSubject.next({ ...this.cardState });
    localStorage.setItem('construction-card', JSON.stringify(listLocale));
    this.constructionCardSubject.next(listLocale);
  }

  setModelCard(card) {
    const tmp = JSON.parse(localStorage.getItem('construction-card'));
    const title = tmp.title;
    const newcard = { ...card, title };
    this.constructionCardSubject.next(newcard);
    localStorage.setItem('construction-card', JSON.stringify(newcard));
    this.initCardState(newcard.blocs);
  }

  getCardConfigId(id){
    return this.cardHttpService.getJsonCardId(id);
  }

  getPrintId<CardConfig>(card) {
    return this.cardHttpService.getJsonCardId<string, CardConfig>(card.idConfigCard)
      .pipe(
        map(res => res.card)
  );
  }

}
