import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { map, tap, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { CartesService } from '../services/cartes/cartes.service';

interface InfoUser {
  email: string;
  universe: string;
  status: string;
  idUser?: string;
  priceMode?: string;
  universesAvailable?: string[];
  typeHotel?: string;
}


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private token: string;
  private isAuth = false;
  private status: string;
  private email: string;
  private universe: string;
  private urlApi = environment.urlApi;
  private hostInMail = environment.urlInMail;
  private firstConnexion;
  private typeHotel;
  private priceMode;
  private universeSubject = new BehaviorSubject<string>(null);
  private statusSubject = new BehaviorSubject<string>(null);

  private priceModeSubject = new BehaviorSubject<string>(null);

  private infoUserSubject = new BehaviorSubject<InfoUser>(null);
  constructor(private _http: HttpClient, private router: Router) { }

  getInfoUserListener() {
    return this.infoUserSubject.asObservable();
  }
  getStatusListener() {
    return this.statusSubject.asObservable();
  }
  getPriceModeListener() {
    return this.priceModeSubject.asObservable();
  }
  getFirstConnexion() {
    return this.firstConnexion;
  }




  setFirstConnexion(bool) {
    this.firstConnexion = bool;
  }

  updatePriceMode(mode) {
    this.priceModeSubject.next(mode);
  }
  updateProfilListener(profil) {
    console.log("PROFIL UPDATE IN SERVICE", profil);
    const test = {
      email: "user-styles@margecommunication.com",
      priceMode: "standard",
      status: "User",
      typeHotel: "ibisstyles1",
      universe: "ibis_styles"
    }
    this.infoUserSubject.next({
      ...this.infoUserSubject.getValue(),
      priceMode: profil.rangePrice,
      typeHotel: profil.typeHotel
    })

  }
  getCurrentUniverseListener() {
    return this.universeSubject.asObservable();
  }

  getToken() {
    return this.token;
  }

  getUniverse() {
    return this.universe;
  }

  getEmail() {
    return this.email;
  }

  getAuth() {
    return this.isAuth;
  }

  getStatus() {
    return this.status;
  }
  getTypeHotel() {
    return this.typeHotel;
  }
  setTypeHotel(typeHotel) {
    this.typeHotel = typeHotel;
    //console.log('setTypeHotel : ', { ...this.infoUserSubject.value, typeHotel: typeHotel });
    this.infoUserSubject.next({ ...this.infoUserSubject.value, typeHotel: typeHotel });
    localStorage.setItem('typeHotel', typeHotel);
  }

  setPriceMode(priceMode) {
    this.priceMode = priceMode;
    this.infoUserSubject.next({ ...this.infoUserSubject.value, priceMode: priceMode });
  }

  patchUniverse(universe, idUser) {
    return this._http.patch<any>(this.urlApi + 'user/' + idUser, { universe }).pipe(
      tap((res: { message: string, result: any }) => {
        this.saveAuthData(res.result.token, res.result.email, res.result.refresh);
        this.token = res.result.token;
        this.universe = universe;
        this.universeSubject.next(universe);
        this.infoUserSubject.next({ ...this.infoUserSubject.value, universe });

      }));
  }

  getSecureStatus(email: string) {
    return this._http.post<{
      status: string,
      universe: string,
      idUser?: string,
      universesAvailable?: string[],
      priceMode?: string,
      typeHotel?: string
    }>(this.urlApi + 'user/getStatus', { email })

      .pipe(tap(data => {
        this.infoUserSubject.next({ ...data, email });
        this.universeSubject.next(data.universe);
        this.statusSubject.next(data.status);
        this.email = email;
        this.priceModeSubject.next(data.priceMode);
        this.universe = data.universe;
        this.status = data.status;
        if (data.typeHotel) {
          this.typeHotel = data.typeHotel;

          localStorage.setItem('typeHotel', this.typeHotel);
        }

        // this.universeSubject.next(data.universe);
        // if (data.priceMode) {
        // }
        // this.universesAvailable = data.universesAvailable;
        // this.idUser = data.idUser;
        // this.statusSubject.next(data.status);
        // this.universe = data.universe;
        // if (data.typeHotel) {
        //   localStorage.setItem('typeHotel', data.typeHotel);
        // }
      }),
        /**
         * Hard stuff over here...
        */
        catchError<{
          status: string,
          universe: string,
          idUser?: string,
          universesAvailable?: string[],
          priceMode?: string,
          typeHotel?: string
        }, Observable<{
          status: string,
          universe: string,
          idUser?: string,
          universesAvailable?: string[],
          priceMode?: string,
          typeHotel?: string
        }>>(err => {
          // simple logging, but you can do a lot more, see below
          // console.log(err.error);
          this.logOut();
          return err.error;
        }));
  }


  connexion(_email: string, _password: string) {
    const authData: any = {
      email: _email,
      password: _password,
    };

    return this._http.post<{
      token: string, refresh: string, userStatus: string,
      email: string, message: string, universe: string,
      firstConnexion: boolean
    }>(this.urlApi + 'user/connexion', authData)
      .pipe(map(res => {
        const _token = res.token;
        this.token = _token;

        this.universe = res.universe;
        this.universeSubject.next(res.universe);
        this.saveAuthData(res.token, res.email, res.refresh);

        this.firstConnexion = res.firstConnexion;
        if (_token) {

          this.isAuth = true;
          if (this.universe === 'marge') {
            this.router.navigate(['ultra']);
          } else {
            this.router.navigate(['accueil']);

          }
          this.status = res.userStatus;
          this.email = res.email;
        }
        return res;
      }));

  }

  logOut() {
    this.universe = null;
    this.token = null;
    this.isAuth = false;
    this.status = null;
    this.typeHotel = null;
    this.clearAuthData();
    localStorage.removeItem('construction-card');
    localStorage.removeItem('typeHotel');
    window.localStorage.removeItem('construction-card');
    this.router.navigate(['/connexion']);
  }

  refreshToken(token) {
    return this._http.post<{ token: string, refresh: string }>(this.urlApi + 'user/refresh', { token: token })
      .pipe(
        tap(res => {
          localStorage.setItem('token', res.token);
          localStorage.setItem('refresh', res.refresh);
        }));
  }

  private saveAuthData(token: string, email: string, refresh: string) {
    localStorage.setItem('token', token);
    localStorage.setItem('refresh', refresh);
    localStorage.setItem('email', email);
  }

  private clearAuthData() {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('refresh');
  }

  autoAuthUser() {
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('email');

    if (!token || !email) {
      return;
    } else {
      this.isAuth = true;
      this.token = token;
      this.email = email;
      return this.getStatusListener().subscribe(res => {
        this.status = res;
      });
    }
  }

  sendMail(email: string, type = 'reset') {
    return this._http.post<{ message: string }>(this.urlApi + 'auth/sendMail', {
      email: email,
      hostInMail: this.hostInMail,
      type: type
    });
  }

  verifyToken(token: string, date: Number) {
    return this._http.post<{ message: string, email: string }>
      (this.urlApi + 'auth/verifyToken', { resetPasswordToken: token, date: date });
  }

  deleteToken(token: string) {
    return this._http.post(this.urlApi + 'auth/deleteToken', { token: token });
  }

  resetPassword(newPassword: string, email: string) {
    return this._http.post<{ message: string, updated: boolean }>
      (this.urlApi + 'auth/resetPassword', { email: email, newPassword: newPassword });
  }

  changeOwnPassword(email: string, currentPwd: string, newPwd: string) {
    return this._http.post(this.urlApi + 'auth/changePassword',
      { email: email, currentPassword: currentPwd, newPassword: newPwd });
  }

}

