import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfigService } from '../../../../core/services/config/config.service';

@Component({
  selector: 'app-universe-admin',
  templateUrl: './universe-admin.component.html',
  styleUrls: ['./universe-admin.component.scss']
})
export class UniverseAdminComponent implements OnInit {
  configs;
  selectable = false;
  removable = true;
  addOnBlur = true;
  config;
  isEdit = false
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  universeForm = new FormGroup({
    universe: new FormControl('', [Validators.required]),
    subUniverses: new FormArray([]),
    pricesRanges: new FormArray([]),
    recipeLabels: new FormArray([]),
    cardTypes: new FormArray([]),
  })
  constructor(private configService: ConfigService) { }

  get subUniverses() {
    return this.universeForm.get("subUniverses") as FormArray;
  }
  get pricesRanges() {
    return this.universeForm.get("pricesRanges") as FormArray;
  }
  get recipeLabels() {
    return this.universeForm.get("recipeLabels") as FormArray;
  }
  get cardTypes() {
    return this.universeForm.get("cardTypes") as FormArray;
  }

  createSubUniverses(slug?, label?) {
    return new FormGroup({
      slug: new FormControl(slug || ''),
      label: new FormControl(label || '')
    })
  }
  createRecipeLabels(slug?, label?, color?) {
    return new FormGroup({
      slug: new FormControl(slug || ''),
      label: new FormControl(label || ''),
      color: new FormControl(color || '')
    })
  }

  addSubUniverses() {
    this.subUniverses.push(this.createSubUniverses())
    console.log(this.universeForm.get("subUniverses"));
  }
  addPricesRanges() {
    this.pricesRanges.push(this.createSubUniverses())
    console.log(this.universeForm.get("pricesRanges"));
  }
  addRecipeLabels() {
    this.recipeLabels.push(this.createRecipeLabels())
    console.log(this.universeForm.get("recipeLabels"));
  }
  addCardTypes() {
    this.cardTypes.push(this.createRecipeLabels())
    console.log(this.universeForm.get("cardTypes"));
  }

  ngOnInit() {
    this.configService.getConfig().subscribe(configs => {
      console.log(configs);

      this.configs = configs
    })
  }

  remove(value, controlsName) {
    console.log("remove", value);
    const controls = this.universeForm.get(controlsName)
    const currentValue = controls.value
    const index = currentValue.indexOf(value);
    if (index >= 0) {
      currentValue.splice(index, 1);
    }
    this.universeForm.get(controlsName).setValue([...currentValue])


  }
  add(event, controlsName) {
    console.log("add", event, controlsName);
    if (!event.value) return;
    const input = event.input;
    const controls = this.universeForm.get(controlsName)
    const currentValue = controls.value
    this.universeForm.get(controlsName).setValue([...currentValue, event.value])
    console.log(controls, [...currentValue, event.value]);
    if (input) {
      input.value = '';
    }
  }

  reset() {
    this.universeForm.reset()
    this.universeForm = new FormGroup({
      universe: new FormControl('', [Validators.required]),
      subUniverses: new FormArray([]),
      pricesRanges: new FormArray([]),
      recipeLabels: new FormArray([]),
      cardTypes: new FormArray([]),
    })
  }
  save() {
    console.log("save", this.universeForm);

    if (this.universeForm.invalid) {
      console.log('form invalide');
      return;
    }
    if (this.isEdit) {
      this.configService.patchConfigById(this.config._id, this.universeForm.value).subscribe(res => {
        this.isEdit = false
        this.reset()
        // reset FORM ARRAY
      }, err => {
        console.log("error patch");

      })
    } else {
      this.configService.postConfig(this.universeForm.value).subscribe(res => {
        this.isEdit = false
        this.reset()
      }, err => {
        console.log("error post");

      })
    }

  }

  create() {
    this.isEdit = false
    this.reset()
    //reset form
  }
  edit(config) {
    this.isEdit = true
    console.log(config);
    this.config = config
    this.reset()
    this.universeForm.patchValue({
      universe: config.universe,
    })
    config.subUniverses.map(sub => {
      this.subUniverses.push(this.createSubUniverses(sub.slug, sub.label))
    })
    config.pricesRanges.map(sub => {
      this.pricesRanges.push(this.createSubUniverses(sub.slug, sub.label))
    })
    config.recipeLabels.map(sub => {
      this.recipeLabels.push(this.createRecipeLabels(sub.slug, sub.label, sub.color))
    })
    config.cardTypes.map(sub => {
      this.cardTypes.push(this.createSubUniverses(sub.slug, sub.label))
    })

    console.log(this.universeForm.get('subUniverses'));

  }

}
