import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-single-video',
  templateUrl: './single-video.component.html',
  styleUrls: ['./single-video.component.scss']
})
export class SingleVideoComponent implements OnInit {
  @Input() urlvideo: string;
  @Input() simplifiedView = false;
  constructor() { }

  ngOnInit() {
  }

}
