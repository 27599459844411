import { Component, OnInit, OnDestroy } from '@angular/core';
import { Recipe } from '../../../../shared/models/recipe.model';
import { RecipesService } from '../../../core/services/recettes/recipes.service';


@Component({
  selector: 'app-recipes-list',
  templateUrl: './recipes-list.component.html',
  styleUrls: ['./recipes-list.component.scss']
})
export class RecipesListComponent implements OnInit {
  recipes: Recipe[] = [];
  searchTerm = '';
  constructor(public recipeService: RecipesService) { }

  ngOnInit() {
  //  this.recipeService.getAllRecipes().subscribe();
  }

}
