export const CARD_TYPES = ['restaurant', 'room', 'bar'];

export const RECIPE_TYPES = ['entree', 'plat', 'pizza', 'pastaRisotto', 'dessert'];

export const COCKTAIL_TYPES = [''];

export const DRINK_TYPES = [''];

export const URL_TYPES = {
    entree: { url: '/recettes/entrees', linkName: 'Entrées', slug: RECIPE_TYPES[0] },
    plat: { url: '/recettes/plats', linkName: 'Plats', slug: RECIPE_TYPES[1] },
    pizza: { url: '/recettes/pizzas', linkName: 'Pizzas', slug: RECIPE_TYPES[2] },
    pastaRisotto: { url: '/recettes/pastas-risottos', linkName: 'Pastas et risottos', slug: RECIPE_TYPES[3] },
    dessert: { url: '/recettes/desserts', linkName: 'Desserts', slug: RECIPE_TYPES[4] },
};

export const NAMED_CAT_TYPES = {
    'Entrée': URL_TYPES.entree.slug,
    'Pizza': URL_TYPES.pizza.slug,
    'Plat': URL_TYPES.plat.slug,
    'Pasta et risotto': URL_TYPES.pastaRisotto.slug,
    'Dessert': URL_TYPES.dessert.slug,
};

export const TYPE_HOTEL = {
    novotel: 'Novotel',
    gourmetBar: 'Gourmet Bar',
    Gourmetbar: 'Gourmet Bar,'
};
