import { tap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Cocktail } from './../../../../shared/models/cocktail.model';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CocktailsService {

  private apiUrl = environment.urlApi;
  private allCocktails: Cocktail[] = [];
  private allCocktailsSubject = new BehaviorSubject<Cocktail[]>(null);

  constructor(private _http: HttpClient) { }

  getAllCocktailsListener() {
    return this.allCocktailsSubject.asObservable();
  }
  getAllCocktails() {
    return this._http.get<{ message: string, result: Cocktail[] }>(this.apiUrl + 'cocktails').pipe(tap(
      resultat => {
        this.allCocktails = resultat.result;
        this.allCocktailsSubject.next([...this.allCocktails]);
      }
    ),
      map(res => res.result));
  }

  getCocktailsByCardType(type: string) {
    return this._http.get<any>(`${this.apiUrl}cocktails/byCardType?type=${type}`).pipe(map(res => res.cocktails));
  }

  getCocktailById(cocktailId: string) {
    return this._http.get<{ message: string, cocktail: Cocktail }>(this.apiUrl + 'cocktails/' + cocktailId)
      .pipe(map(result => result.cocktail));
  }

  patchCocktailById(cocktailData: FormData, cocktailId: string) {
    return this._http.patch<{ message: string, newCocktail: Cocktail }>(this.apiUrl + 'cocktails/' + cocktailId, cocktailData).pipe(
      tap(res => {
        if (res.newCocktail) {
          const index = this.allCocktails.findIndex(c => c._id === cocktailId);
          this.allCocktails[index] = res.newCocktail;
          this.allCocktailsSubject.next([...this.allCocktails]);
        }
      })
    );
  }

  postCocktail(cocktailData: FormData) {
    return this._http.post<{ message: string, newCocktail: Cocktail }>(this.apiUrl + 'cocktails/', cocktailData).pipe(tap(res => {
      this.allCocktails.unshift(res.newCocktail);
      this.allCocktailsSubject.next([...this.allCocktails]);
    }));
  }

  deleteCocktail(cocktailId) {
    return this._http.delete<{ message: string }>(this.apiUrl + 'cocktails/' + cocktailId).pipe(tap(
      () => {
        const index = this.allCocktails.findIndex(d => d._id === cocktailId);
        this.allCocktails.splice(index, 1);
        this.allCocktailsSubject.next([...this.allCocktails]);
      }
    ));
  }


}
