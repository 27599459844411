import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/core/authentification/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Cocktail } from 'src/shared/models/cocktail.model';
import { CocktailsService } from 'src/app/core/services/cocktails/cocktails.service';

@Component({
  selector: 'app-thumbnail-cocktail',
  templateUrl: './thumbnail-cocktail.component.html',
  styleUrls: ['./thumbnail-cocktail.component.scss']
})
export class ThumbnailCocktailComponent implements OnInit, OnDestroy {

  @Input() cocktail: Cocktail;

  status = '';
  currentUrl = '';

  showModal = false;
  isLoading: Boolean;

  deleteSub: Subscription;
  constructor(private authStatus: AuthService,
              private router: Router,
              private cocktailService: CocktailsService) { }

  ngOnInit() {
    this.authStatus.getStatusListener().subscribe(status => {
      if (status) {
        this.status = status;
      }
  });
    this.currentUrl = this.router.url;
  }

  onDelete(recipeID) {
    this.deleteSub = this.cocktailService.deleteCocktail(recipeID).subscribe();
  }

  ngOnDestroy() {
    if (this.deleteSub) {
      this.deleteSub.unsubscribe();
    }
  }
}
