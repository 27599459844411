import ValidationMethods from "./ValidationMethod";
/**
 * Méthode d'origine avec cat_ob, rl_fa
 * on peut set une valeur de recettes venant du catalogue obligatoirement et un nombre de recettes libres facultatives
 * non implémenté : la notion de catalogue facultatif et recettes libres obligatoires
 */
export default class CatRl extends ValidationMethods {

  constructor() {
    super()
  }
  /**
   * TODO
   * @param bloc
   */
  isMethods(bloc): Boolean {
    return true
  }

  /**
  * Bloc + categories confondu
  * @param bloc un bloc de la carte
  */
  totalBlocFreeRecipe(bloc) {
    let acc = 0;
    if ('recettes' in bloc) {
      acc += this.totalFreeRecipe(bloc.recettes);
    }
    if ('categories' in bloc) {
      // tslint:disable-next-line:no-shadowed-variable
      acc += bloc.categories.reduce((acc, val) => {
        return acc + this.totalFreeRecipe(val.recettes);
      }, 0);
    }
    return acc;
  }

  totalFreeRecipe(recettes: []) {
    if (!recettes) { return; }
    const total = recettes.reduce((acc, val: any) => {
      if ('freeRecipe' in val && val.freeRecipe) {
        return acc + 1;
      } else {
        return acc;
      }
    }, 0);
    return total;
  }

  totalCatalogRecipe(recettes: []) {
    if (!recettes) { return; }
    const total = recettes.length - this.totalFreeRecipe(recettes);
    return total;
  }

  totalBlocRecipe(bloc) {
    let acc = 0;
    if ('recettes' in bloc) {
      acc += bloc.recettes.length;
    }
    if ('categories' in bloc) {
      // tslint:disable-next-line:no-shadowed-variable
      acc += bloc.categories.reduce((acc, val) => {
        return acc + val.recettes.length;
      }, 0);
    }
    return acc;
  }

  totalBlocCatalogRecipe(bloc) {
    const total = this.totalBlocRecipe(bloc) - this.totalBlocFreeRecipe(bloc);
    return total;
  }

  /**
   * check if a bloc is full
   * @param bloc a bloc of the card
   * @param type recette, recette_libre, all
   * @param parent the parent bloc of the bloc
   * @returns boolean isFull
   */
  isFullBloc(bloc, type, parent?) {
    let isFull = false
    switch (type) {
      case 'all':
        let countAll = bloc.cat_obl;
        if ('rl_fa' in bloc) {
          countAll += bloc.rl_fa;
        } else {
          countAll += 'rl_fa' in bloc ? bloc.rl_fa : 0;
          countAll += 'cat_fa' in bloc ? bloc.cat_fa : 0;
        }
        isFull = countAll === this.totalBlocCatalogRecipe(parent);
        break;
      case 'recettes_libres':
        const totalFreeRecipe = this.totalBlocFreeRecipe(bloc);
        if (('rl_fa' in bloc)) {
          isFull = bloc.rl_fa === totalFreeRecipe;
        } else if (!('rl_fa' in bloc) && parent) {
          const totalBlocFreeRecipe = this.totalBlocFreeRecipe(parent);
          isFull = parent.rl_fa <= totalBlocFreeRecipe;
        } else {
          isFull = true;
        }
        break;
      case 'recettes':
        const catalogRecipe = this.totalBlocCatalogRecipe(bloc);
        if ('cat_obl' in bloc) {
          // console.log('cat_obl');
          isFull = bloc.cat_obl === catalogRecipe;
        } else if (parent) {
          // console.log('no cat_obl');
          isFull = parent.cat_obl === this.totalBlocCatalogRecipe(parent);
        }

        if ('cat_obl' in bloc && 'recettes' in bloc) {
          isFull = bloc.cat_obl === catalogRecipe;
        } else if (!('cat_obl' in bloc) && "categories" in bloc) {  // cas total mais y'a pas de total sur le bloc
          isFull = !bloc.categories.some((c, i) => {
            return !this.isFullBloc(c, 'recettes', bloc);
          });
        } else if ('cat_obl' in bloc && !('recettes' in bloc)) {
          isFull = bloc.cat_obl <= catalogRecipe;
        }
        break;
    }

    return isFull
  }

  isMinRequiredBloc(bloc, type, parent?) {
    return true;
  }
}
