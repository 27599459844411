import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class Auth0Service {
  private apiUrl = environment.urlApi;

  constructor(private _http: HttpClient) {
  }

  authMagento() {
    return this._http.get(this.apiUrl + 'auth0/authMagento');
  }

  getToken() {
    return this._http.get(this.apiUrl + 'auth0/getPermanentToken');
  }

  sendCartes(json, aboutCard) {
    return this._http.post(this.apiUrl + 'auth0/send-cartes', { ...aboutCard, chili: json });
  }

  getUrlRedirection() {
    return this._http.get<any>(this.apiUrl + 'auth0/url-redirection');
  }
}
