import { Pipe, Injectable, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
    name: 'filesURL'
})

@Injectable()
export class FilesUrlPipe implements PipeTransform {
    transform(path: string) {
        return environment.baseUrlBack + path;
    }
}
