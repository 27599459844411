import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-single-ingredients',
  templateUrl: './single-ingredients.component.html',
  styleUrls: ['./single-ingredients.component.scss']
})
export class SingleIngredientsComponent implements OnInit {
  @Input() ingredients: string[];
  @Input() simplifiedView = false;
  widthOfLine = 0;
  rotation = 0;

  constructor(public el: ElementRef) { }

  ngOnInit() { }

}
