import { Component, OnInit, Input } from '@angular/core';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { FilesUrlPipe } from 'src/app/shared/pipes/files-url/files-url.pipe';

@Component({
  selector: 'app-single-downloads',
  templateUrl: './single-downloads.component.html',
  styleUrls: ['./single-downloads.component.scss'],
  providers: [FilesUrlPipe]
})
export class SingleDownloadsComponent implements OnInit {

  @Input() downloadPdf: string;
  @Input() downloadImage: string;
  @Input() simplifiedView = false;

  constructor(private sanitizer: DomSanitizer, private filesUrl: FilesUrlPipe) { }

  ngOnInit() {
  }

  cleanURL(oldURL): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.filesUrl.transform(oldURL));
  }

}
