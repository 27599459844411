import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Recipe } from '../../../../../shared/models/recipe.model';
import { RecipesService } from 'src/app/core/services/recettes/recipes.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-desserts-list',
  templateUrl: './desserts-list.component.html',
  styleUrls: ['./desserts-list.component.scss']
})
export class DessertsListComponent implements OnInit, OnDestroy {

  @Input() searchTerm = '';

  private recipeSub: Subscription;

  currentUrl: string;
  dessertsList: Recipe[] = [];

  constructor(public recipeService: RecipesService, private router: Router) { }

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.recipeService.getAllRecipes().subscribe();
    this.recipeSub = this.recipeService.getRecipesUpdateListener()
      .subscribe((recipes: Recipe[]) => {
        if (recipes) {
          this.dessertsList = recipes.filter(recipe => recipe.type === 'dessert');
        }
      });
  }

  ngOnDestroy() {
    this.recipeSub.unsubscribe();
  }

  onDelete(postId: string) {
    this.recipeService.deleteRecipe(postId).subscribe();
  }

}
