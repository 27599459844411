import { Admin } from '../../../../../shared/models/admin.model';
import { ProfilService } from 'src/app/core/services/profil/profil.service';
import { Component, OnInit, OnDestroy, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { AdminService } from '../../../../core/services/admin/admin.service';
import { Subscription } from 'rxjs';
import { ConfigService } from '../../../../core/services/config/config.service';

@Component({
  selector: 'app-ultra-admin',
  templateUrl: './ultra-admin.component.html',
  styleUrls: ['./ultra-admin.component.scss']
})
export class UltraAdminComponent implements OnInit, OnDestroy {
  aboutToAdd = false;
  errorOnServeur = false;
  userAdded = false;

  addAdminSub: Subscription;
  getAllAdminsSub: Subscription;
  getUniversePossibles: Subscription;

  ultraAdminForm = new FormGroup({
    email: new FormControl('', [Validators.email]),
    password: new FormControl('', Validators.required),
    checkUniverses: new FormArray([], [Validators.required])
  });

  allAdmins: Admin[] = [];
  universePossibles = [{ title: "Marge", value: "marge" }];

  /**
   * ViewChildren to later use is to populates
   * the checkboxes
   */
  @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>;

  constructor(private adminService: AdminService,
    private profilService: ProfilService,
    private configService: ConfigService) { }

  ngOnInit() {
    this.getAllAdminsSub = this.profilService.getAllAdmins().subscribe(res => {
      this.allAdmins = res.users.filter(u => u.email !== localStorage.getItem('email'));
    });

    this.getUniversePossibles = this.configService.getConfigListener().subscribe(res => {
      if (res) {
        console.log("univers possibles ", res);
        for (let univers of res) {
          this.universePossibles = [...this.universePossibles, { title: univers.universe, value: univers.universe }]
        }
      }
    });
  }

  onCheckboxChange(e) {
    const checkArray: FormArray = this.ultraAdminForm.get('checkUniverses') as FormArray;

    if (e.checked) {
      checkArray.push(new FormControl(e.source.value));
    } else {
      let i = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value === e.source.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }


  handleDeleteAdmin(e, index) {
    if (e) {
      this.allAdmins.splice(index, 1);
    }
  }

  onInscription() {

    if (this.ultraAdminForm.invalid) {
      return;
    }

    this.addAdminSub = this.adminService.addAdmin(this.ultraAdminForm.value.email,
      this.ultraAdminForm.value.password,
      this.ultraAdminForm.value.checkUniverses).subscribe(res => {
        this.errorOnServeur = false;
        this.userAdded = true;
        this.checkboxes['_results'].forEach((element) => {
          element._checked = false;
        });
        this.ultraAdminForm.reset();
        this.allAdmins.push(res.result);
      }, (error) => {
        this.errorOnServeur = true;
      });
  }

  ngOnDestroy() {
    if (this.addAdminSub) {
      this.addAdminSub.unsubscribe();
    }
    if (this.getAllAdminsSub) {
      this.getAllAdminsSub.unsubscribe();
    }
    if (this.getUniversePossibles) {
      this.getUniversePossibles.unsubscribe();
    }
  }

}
