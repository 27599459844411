import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomIconService {
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) { }
    init() {
        this.matIconRegistry.addSvgIcon(
            'catalog',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/big-brochure.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'custom-recipe',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/text.svg')
        );
    }
}
