import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'myfilter',
    pure: false
})
export class MyFilterPipe implements PipeTransform {
    transform(users: any[], userStatus: string[]): any {
        if (!users || userStatus.length === 0) {
            return users;
        }
        return users.filter(item => userStatus.includes(item['status']));
    }
}
