import ValidationMethods from "./ValidationMethod";

export default class MinMax extends ValidationMethods {

  isMethods(bloc) {
    return true
  }

  totalBlocRecipe(bloc) {
    let acc = 0;
    if ('recettes' in bloc) {
      acc += bloc.recettes.length;
    }
    if ('categories' in bloc) {
      // tslint:disable-next-line:no-shadowed-variable
      acc += bloc.categories.reduce((acc, val) => {
        return acc + val.recettes.length;
      }, 0);
    }
    return acc;
  }


  isFullBloc(bloc, type, parent?) {
    let isFull = true;
  const min = bloc.min;
  const max = bloc.max;
  const countAll = this.totalBlocRecipe(bloc);
  if (countAll < max) {
    isFull = false;
  } else {
    isFull = true;
  }
    return isFull;
  }

  isMinRequiredBloc(bloc, type, parent?) {
    let isMinRequired = true;
    const min = bloc.min;
    const max = bloc.max;
    const countAll = this.totalBlocRecipe(bloc);
    if (countAll < min) {
      isMinRequired = false;
    } else {
      isMinRequired = true;
    }
    return isMinRequired;
  }

}
