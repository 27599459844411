import { Router } from '@angular/router';
import { RecipesService } from 'src/app/core/services/recettes/recipes.service';
import { Recipe } from '../../../../../shared/models/recipe.model';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pizzas-list',
  templateUrl: './pizzas-list.component.html',
  styleUrls: ['./pizzas-list.component.scss']
})
export class PizzasListComponent implements OnInit, OnDestroy {

  @Input() searchTerm = '';
  private recipeSub: Subscription;
  currentUrl: string;
  pizzasList: Recipe[] = [];
  constructor(public recipeService: RecipesService, private router: Router) { }

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.recipeService.getAllRecipes().subscribe();

    this.recipeSub = this.recipeService.getRecipesUpdateListener()
    .subscribe((recipes: Recipe[]) => {
      if (recipes) {
        this.pizzasList = recipes.filter(recipe => recipe.type === 'pizza');

      }
    });
  }

  ngOnDestroy() {
    this.recipeSub.unsubscribe();
  }

  onDelete(postId: string) {
    this.recipeService.deleteRecipe(postId).subscribe();
  }

}
