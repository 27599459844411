import { SingleRecipeComponent } from './../../shared/components/single-recipe/single-recipe.component';
import { DessertsListComponent } from './../../shared/components/recipes-list/desserts-list/desserts-list.component';
import { PlatsListComponent } from './../../shared/components/recipes-list/plats-list/plats-list.component';
import { EntreesListComponent } from './../../shared/components/recipes-list/entrees-list/entrees-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddRecipeComponent } from './components/add-recipe/add-recipe.component';

import { ThumbnailRecipeComponent } from '../../shared/components/thumbnail-recipe/thumbnail-recipe.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';
import { RouterModule } from '@angular/router';

import { ProgressBarModule } from 'angular-progress-bar';



import { ImageCropperModule } from 'ngx-image-cropper';

import { BreadcrumbModule } from 'angular-crumbs';

import { ManageRecipesComponent } from './components/manage-recipes/manage-recipes.component';

import { ToastrModule } from 'ngx-toastr';
import { CorrectPrice } from '../../shared/pipes/filter-price/price-filter.pipe';
import { ArrayRecipesComponent } from './components/array-recipes/array-recipes.component';
import { SharedModule } from '../../shared/shared.module';
import { PizzasListComponent } from 'src/app/shared/components/recipes-list/pizzas-list/pizzas-list.component';
import { PastasRisottoComponent } from 'src/app/shared/components/recipes-list/pastas-risotto/pastas-risotto.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatStepperModule} from '@angular/material/stepper';
import { RecapFormComponent } from './components/add-recipe/recap-form/recap-form.component';
import {MatButtonModule} from '@angular/material/button';

import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
  imports: [
    MatCheckboxModule,
    MatButtonModule,
    SharedModule,
    MatStepperModule,
    BreadcrumbModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TagInputModule,
    RouterModule,
    ProgressBarModule,
    ImageCropperModule,
    ToastrModule.forRoot(),
    SharedModule,
    MatTooltipModule,
    RouterModule.forChild([

      { path: 'entrees', component: EntreesListComponent },
      { path: 'plats', component: PlatsListComponent },
      { path: 'pizzas', component: PizzasListComponent },
      { path: 'pastas-risottos', component: PastasRisottoComponent },
      { path: 'desserts', component: DessertsListComponent },
      { path: 'administration', component: ManageRecipesComponent },

      { path: 'administration/:recipeID', component: AddRecipeComponent },
      { path: ':recipeID', component: SingleRecipeComponent },
    ])
  ],
  declarations: [
    AddRecipeComponent,
    // SingleRecipeComponent,

    ManageRecipesComponent,
    CorrectPrice,
    ArrayRecipesComponent,
    RecapFormComponent,



  ],
  exports: [ThumbnailRecipeComponent]
})
export class RecipeModule { }
