import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Component, OnInit, Input, OnChanges } from '@angular/core';

import { CartesService } from '../../../core/services/cartes/cartes.service';

@Component({
  selector: 'app-sub-form-category-bloc',
  templateUrl: './sub-form-category-bloc.component.html',
  styleUrls: ['./sub-form-category-bloc.component.scss']
})
export class SubFormCategoryBlocComponent implements OnInit {
  @Input() formData;
  @Input() mode;
  // data = definition['default'];
  data = {};
  cards;
  isDataReady = false

  constructor(private carteService: CartesService) { }

  ngOnInit() {
    // console.log('data', this.formData);
    // console.log('this form', this.formData.get('cardConfig').controls);
    console.log("FORMDATAS", this.formData.get('cardConfig').controls);

    this.carteService.getAllUniverseCards().subscribe(res => {
      console.log("getAllUniverseCards :", res);
      this.cards = res
      this.cards.map(c => {
        this.data[c._id] = c

      })
      this.isDataReady = true
      console.log(this.data);

    })
    console.log(this.formData);

  }

  get pricingForm() {
    return this.formData.get('cardConfig') as FormArray;
  }

  getCardKeys() {
    return Object.keys(this.data);
  }

  getCardBlocs(u) {
    if (u) {
      return this.data[u].blocs;
    } return [];
  }

  getSubUniverse(u) {
    //console.log("U : ", this.data);
    if (u) {
      return this.data[u].subUniverse;
    } return [];
  }

  /**
   * nice ! it does something. I'm a function digger now :)
   * @param e guess it's an event
   * @param i probably an index.
   */
  onChangeCarteType(e, i) {
    console.log("onChangeCarteType", e, i, this.data[e.target.value]);

    this.formData.get('cardConfig').controls[i].patchValue({
      bloc: this.data[e.target.value].blocs[0].slug,
      cardType: this.data[e.target.value].cardType
    });
    if (this.data[e.target.value].blocs[0].categories.length > 0) {
      this.formData.get('cardConfig').controls[i].patchValue({
        category: this.data[e.target.value].blocs[0].categories[0].slug
      });
    }
  }

  onChangeBloc(e, i) {
    // console.log("onChangeBloc");
    console.log(this.formData.get('cardConfig').controls);

    const blocSlug = e.target.value;
    const currentCard = this.formData.get('cardConfig').controls[i].get('cardType').value;
    const currentBloc = this.data[currentCard].blocs.find(d => d.slug === blocSlug);
    if ('categories' in currentBloc && currentBloc.categories.length > 0) {
      this.formData.get('cardConfig').controls[i].patchValue({
        category: currentBloc.categories[0].slug
      });
    }
  }

  onChangeConcept(e, i) {
    // console.log("onChangeBloc");
    console.log("concept change :", e.target.value);
  }

  deleteCategoryFromForm(index) {
    const control = <FormArray>this.formData.get('cardConfig');
    delete control.value[index].category;
  }

  /**
   * this function calls for categories.
   * @param index index of cardConfig
   */
  getCategories(cardType, blocSlug, index) {
    console.log("getCategories", cardType, blocSlug, index);

    if (cardType && blocSlug) {
      if (this.data[cardType]) {
        const blocs = this.data[cardType].blocs;
        const cat = blocs.find(u => u.slug === blocSlug);
        if (cat && 'categories' in cat && cat.categories.length > 0) {
          return cat.categories;
        } else {
          console.log("trouble", blocSlug);

        }
      }
    }
    this.deleteCategoryFromForm(index);
    return [];
  }

  onAddCategory() {
    this.pricingForm.push(
      new FormGroup({
        cardConfigId: new FormControl('', {}),
        cardType: new FormControl('', {}),
        bloc: new FormControl('', {}),
        category: new FormControl('', {}),
        must: new FormControl(false, {}),
        subuniverse: new FormControl('', {})
      })
    );
  }

  onTest(singleData = '') {
    // console.log(this.formData, singleData);
  }

  deleteRow(i) {
    const control = <FormArray>this.formData.get('cardConfig');
    control.removeAt(i);
  }

  hasBlocRecettes(cardType, blocName) {
    // console.log("hasBlocRecettes");

    const card = this.data[cardType];
    const bloc = card.blocs.find(b => b.slug === blocName);
    if ('recettes' in bloc) {
      return true;
    }
    return false;
  }
}
