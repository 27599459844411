import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
// import { AuthService } from 'src/app/user-module/services/auth.service';
import { AdminService } from '../../../../core/services/admin/admin.service';
import { AuthService } from 'src/app/core/authentification/auth.service';

@Component({
  selector: 'app-inscription',
  templateUrl: './inscription.component.html',
  styleUrls: ['./inscription.component.scss']
})
export class InscriptionComponent implements OnInit, OnDestroy {

  aboutToAdd = false;
  errorOnServeur = false;
  userAdded = false;
  adminAddUserSub: Subscription;
  typeOfInput = 'password';
  randPassword = '';
  email = '';
  constructor(private adminService: AdminService, private authService: AuthService) { }

  ngOnInit() {
    this.authService.getInfoUserListener().subscribe(res => this.email = res.email);
  }
  generatePwd() {
    this.randPassword = Array(10)
      .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')
      .map((x) => {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join('');

  }



  showPwd() {
    if (this.typeOfInput === 'password') {
      this.typeOfInput = 'text';
    } else {
      this.typeOfInput = 'password';
    }
  }

  onInscription(form: NgForm) {
    if (form.invalid) {
      return;
    }
    this.adminAddUserSub = this.adminService.addUser(form.value.email, form.value.password).subscribe(res => {
      this.errorOnServeur = false;
      this.userAdded = true;
      this.email = '';
      this.randPassword = '';
      // this.authService.sendMail(form.value.email).subscribe(res1 => {
      // });
    }, (error) => {
      this.errorOnServeur = true;
    });


  }

  ngOnDestroy() {
    if (this.adminAddUserSub) {
      this.adminAddUserSub.unsubscribe();
    }
  }

}
