import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { map, tap, single } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private allUser = [];
  private allUsersArrayListener = new Subject<any[]>();
  private urlApi = environment.urlApi;

  constructor(private _http: HttpClient) { }

  getAllUser() {
    return this._http.get<{ message: string, users: any[] }>(this.urlApi + 'admin/users').pipe(map(
      res => {
        //console.log(res);

        return res.users.map(singleUser => {
          return {
            email: singleUser.email,
            status: singleUser.status,
            id: singleUser._id,
            universe: singleUser.universe,
            hotelProfil: singleUser.hotelProfil,
            firstConnexion: singleUser.firstConnexion
          };
        });
      }
    ), map(resultat => {
      this.allUser = resultat;
      this.allUsersArrayListener.next([...this.allUser]);
    }));

  }

  allUsersListen() {
    return this.allUsersArrayListener.asObservable();
  }

  patchUser(userId, user) {
    return this._http.patch(this.urlApi + 'admin/' + userId, user);
  }
  addUser(_email: string, _password: string) {
    const authData = {
      email: _email,
      password: _password,
    };

    return this._http.post<{ message: string, result: any }>(this.urlApi + 'admin/users', authData)
      .pipe(map(res => {
        // console.log('resultat', res);
        const newUser = {
          email: res.result.email,
          status: res.result.status,
          id: res.result._id,
          universe: res.result.universe
        };
        this.allUser.push(newUser);
        this.allUsersArrayListener.next([...this.allUser]);
        return res;
      }
      ));
  }

  deleteUser(id: string) {
    return this._http.delete(this.urlApi + 'admin/users/' + id).pipe(tap(() => {
      this.allUser = this.allUser.filter(user => user.id !== id);
      this.allUsersArrayListener.next([...this.allUser]);
    }));
  }

  addAdmin(_email: string, _password: string, _universesAvailables: string[]) {
    let _universe = 'none';
    // si l'utilisateur n'a qu'un univers
    if (_universesAvailables.length === 1) {
      _universe = _universesAvailables[0];
    }
    //console.log('tu ajoutes', _email, _universesAvailables, _universe);
    const authData = {
      email: _email,
      password: _password,
      universe: _universe,
      universesAvailable: _universesAvailables
    };
    //console.log('tu envoie', authData);

    return this._http.post<{ message: string, result: any }>(this.urlApi + 'admin/addadmin', authData)
      .pipe(map(res => {
        const newUser = {
          email: res.result.email,
          status: res.result.status,
          id: res.result.id,
          universe: res.result.univers,
          universesAvailable: res.result.universesAvailable

        };
        this.allUser.push(newUser);
        this.allUsersArrayListener.next([...this.allUser]);
        return res;
      }
      ));
  }


  deleteAdmin(idUser) {
    return this._http.delete(this.urlApi + 'admin/' + idUser);
  }
}

