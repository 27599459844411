import { Recipe } from '../../../../shared/models/recipe.model';
import { Pipe, Injectable, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
    name: 'filterByBloc'
})

@Injectable()
export class FilterByBloc implements PipeTransform {
    transform(recipes:  {}, blocfilter: string, categoryFilter: string) {
    //     const allRecipes = [];
    //     for (const cat in recipes) {
    //         if (recipes.hasOwnProperty(cat)) {
    //             recipes[cat].forEach(element => {
    //                 allRecipes.push(element);
    //             });
    //         }
    //     }
    //         return (r.cardConfig.filter(f => f.bloc === blocfilter).length > 0 &&
    //         r.cardConfig.filter(f => f.category === categoryFilter).length > 0) ;
    //   }) )
    //     return allRecipes.filter(r => {
    //           return (r.cardConfig.filter(f => f.bloc === blocfilter).length > 0 &&
    //           r.cardConfig.filter(f => f.category === categoryFilter).length > 0) ;
    //     });
    }
}
