import { Component, OnInit, OnDestroy } from '@angular/core';
import { RecipesService } from '../../../../core/services/recettes/recipes.service';
import { Subscription } from 'rxjs';
import { Recipe } from 'src/shared/models/recipe.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-array-recipes',
  templateUrl: './array-recipes.component.html',
  styleUrls: ['./array-recipes.component.scss']
})
export class ArrayRecipesComponent implements OnInit, OnDestroy {
  private subRecipes: Subscription;
  allTheRecipes: Recipe[] = [];
  currentUrl = '';
  selectedId: string;
  selectedTitle: string;
  inputSearch = '';
  showModal = false;
  constructor(private recipeService: RecipesService, private router: Router) { }

  ngOnInit() {
    this.recipeService.getAllRecipes().subscribe();
    this.currentUrl = this.router.url;
    this.subRecipes = this.recipeService.getRecipesUpdateListener().subscribe(res => {
      // console.log('res array', res);
      if (res) {
        this.allTheRecipes = res.reverse();
      }
    });
  }

  selectRecipe(id, title) {
    this.selectedTitle = title;
    this.selectedId = id;
  }

  onDeleteRecipe(id) {
    this.recipeService.deleteRecipe(id).subscribe();
  }

  onGoToModify(_id) {
    this.router.navigate(['/recettes/administration', _id]);
  }

  onGoToRecipe(_id) {
    this.router.navigate(['/recettes', _id]);
  }

  ngOnDestroy() {
    this.subRecipes.unsubscribe();
  }

}
