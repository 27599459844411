import { AnglaisFormComponent } from './components/anglais-recipe/anglais-form.component';
import { MatRadioModule } from '@angular/material/radio';
import { FilterByCategory } from './pipes/filter-recipe-type/filter-recipe.category.pipe';
import { FilterByBloc } from './pipes/filter-recipe-type/filter-recipe-bloc.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SubFormCategoryBlocComponent } from './components/sub-form-category-bloc/sub-form-category-bloc.component';
import { FilterTypeRecipePipe } from './pipes/filter-type-recipe/filter-type-recipe.pipe.ts.service';
import { SanitizeHtmlPipe } from './pipes/sanitize-html/sanitize-html.pipe';
import { MyFilterText } from './pipes/filter-text/filter-text.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PlatsListComponent } from './components/recipes-list/plats-list/plats-list.component';
import { EntreesListComponent } from './components/recipes-list/entrees-list/entrees-list.component';
import { DessertsListComponent } from './components/recipes-list/desserts-list/desserts-list.component';
import { RecipesListComponent } from './components/recipes-list/recipes-list.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ThumbnailActualityComponent } from './components/thumbnail-actuality/thumbnail-actuality.component';
import { NgModule } from '@angular/core';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { AlphabeticalOrder } from './pipes/alphabetical-order/alphabetical-order.pipe';
import { PastasRisottoComponent } from './components/recipes-list/pastas-risotto/pastas-risotto.component';
import { PizzasListComponent } from './components/recipes-list/pizzas-list/pizzas-list.component';
import { RecipesFilter } from './pipes/filter-recipe/recipe-filter.pipe';
import { ThumbnailRecipeComponent } from './components/thumbnail-recipe/thumbnail-recipe.component';
import { MyFilterPipe } from './pipes/filter-checkbox/filter-checkbox.pipe';
import { FilterActuPipe } from './pipes/filter-actu/filter-actu.pipe';
import { SingleRecipeComponent } from './components/single-recipe/single-recipe.component';
import { SingleTopComponent } from './components/single-recipe/single-top/single-top.component';
import { SingleBreadcrumbComponent } from './components/single-recipe/single-breadcrumb/single-breadcrumb.component';
import { SingleImageComponent } from './components/single-recipe/single-image/single-image.component';
import { SingleInfosComponent } from './components/single-recipe/single-infos/single-infos.component';
import { SinglePlusRecipeComponent } from './components/single-recipe/single-plus-recipe/single-plus-recipe.component';
import { SingleDownloadsComponent } from './components/single-recipe/single-downloads/single-downloads.component';
import { SingleVideoComponent } from './components/single-recipe/single-video/single-video.component';
import { SingleIngredientsComponent } from './components/single-recipe/single-ingredients/single-ingredients.component';
import { SingleCocktailComponent } from './components/single-cocktail/single-cocktail.component';
import { CarouselCocktailsComponent } from './components/carousel-cocktails/carousel-cocktails.component';
import { CarouselModule } from 'primeng/carousel';
import { ButtonModule } from 'primeng/button';
import { MatButtonModule, MatFormFieldModule, MatSelectModule, MatProgressSpinnerModule } from '@angular/material';
import { FilesUrlPipe } from './pipes/files-url/files-url.pipe';
import { ThumbnailCocktailComponent } from './components/thumbnail-cocktail/thumbnail-cocktail.component';
import { MesCartesListComponent, DeleteModalComponent } from './components/mes-cartes/mes-cartes-list.component';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';

import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
    imports: [CommonModule, RouterModule, FormsModule, MatInputModule, ReactiveFormsModule, MatCheckboxModule,
        CarouselModule, ButtonModule, MatButtonModule, MatIconModule, MatTooltipModule, MatRadioModule,
        MatChipsModule, MatFormFieldModule, MatSelectModule, MatProgressSpinnerModule, MatDialogModule, MatCardModule,
        MatSnackBarModule
    ],
    declarations: [
        SingleTopComponent,
        SingleBreadcrumbComponent,
        SingleImageComponent,
        SingleInfosComponent,
        SinglePlusRecipeComponent,
        SingleDownloadsComponent,
        SingleVideoComponent,
        SingleIngredientsComponent,
        MesCartesListComponent,
        LoadingSpinnerComponent,
        RecipesListComponent,
        DessertsListComponent,
        EntreesListComponent,
        PastasRisottoComponent,
        PizzasListComponent,
        PlatsListComponent,
        ThumbnailRecipeComponent,
        ThumbnailActualityComponent,
        SingleRecipeComponent,
        RecipesFilter,
        MyFilterPipe,
        MyFilterText,
        SanitizeHtmlPipe,
        FilterActuPipe,
        AlphabeticalOrder,
        FilterTypeRecipePipe,
        FilesUrlPipe,
        CarouselCocktailsComponent,
        SingleCocktailComponent,
        ThumbnailCocktailComponent,
        SubFormCategoryBlocComponent,
        FilterByBloc,
        FilterByCategory,
        AnglaisFormComponent,
        DeleteModalComponent,
    ],
    exports: [
        LoadingSpinnerComponent,
        RecipesListComponent,
        DessertsListComponent,
        EntreesListComponent,
        PastasRisottoComponent,
        PizzasListComponent,
        PlatsListComponent,
        ThumbnailRecipeComponent,
        ThumbnailActualityComponent,
        SingleRecipeComponent,
        SingleCocktailComponent,
        RecipesFilter,
        MyFilterPipe,
        MyFilterText,
        SanitizeHtmlPipe,
        FilterActuPipe,
        AlphabeticalOrder,
        FilterTypeRecipePipe,
        FilesUrlPipe,
        MesCartesListComponent,
        ThumbnailCocktailComponent,
        SubFormCategoryBlocComponent,
        FilterByBloc,
        FilterByCategory,
        MatIconModule,
        MatTooltipModule,
        MatInputModule,
        MatRadioModule,
        AnglaisFormComponent,
        MatFormFieldModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        FormsModule,
        MatCardModule,
        MatButtonModule,
        MatSnackBarModule,
        MatCheckboxModule,
    ],
    entryComponents: [DeleteModalComponent]
})
export class SharedModule { }
