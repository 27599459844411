import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-recap-form',
  templateUrl: './recap-form.component.html',
  styleUrls: ['./recap-form.component.scss']
})
export class RecapFormComponent implements OnInit {
  @Input() recipeForm;
  constructor() { }

  ngOnInit() {
    // console.log('this recipe form', this.recipeForm);
  }

}
