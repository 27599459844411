import { Config } from './../../../../shared/models/config.model';
import { map, take } from 'rxjs/operators';
import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private apiUrl = environment.urlApi;
  configBehaviour = new BehaviorSubject(null)

  constructor(private _http: HttpClient) { }

  getConfig() {
    return this._http.get<{ message: string, config: Config }>(this.apiUrl + 'config').pipe(take(1), map(res => {

      this.configBehaviour.next(res.config)
      return res.config;
    }));
  }

  getConfigListener() {
    return this.configBehaviour.asObservable()
  }

  patchConfig(newConfig) {
    return this._http.patch(this.apiUrl + 'config', newConfig);
  }
  patchConfigById(id, newConfig) {
    return this._http.patch(this.apiUrl + 'config/' + id, newConfig);
  }

  postConfig(config) {
    return this._http.post(this.apiUrl + 'config', config);
  }
}
