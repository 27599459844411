import { Router } from '@angular/router';
import { RecipesService } from 'src/app/core/services/recettes/recipes.service';
import { Recipe } from '../../../../../shared/models/recipe.model';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pastas-risotto',
  templateUrl: './pastas-risotto.component.html',
  styleUrls: ['./pastas-risotto.component.scss']
})
export class PastasRisottoComponent implements OnInit, OnDestroy {
  @Input() searchTerm = '';
  private recipeSub: Subscription;
  currentUrl: string;
  pastaRisottoList: Recipe[] = [];
  constructor(public recipeService: RecipesService, private router: Router) { }

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.recipeService.getAllRecipes().subscribe();

    this.recipeSub = this.recipeService.getRecipesUpdateListener()
      .subscribe((recipes: Recipe[]) => {
        // console.log('les recdettes', recipes);
        if (recipes) {
          this.pastaRisottoList = recipes.filter(recipe => recipe.type === 'pastaRisotto');
        }
      });
  }

  ngOnDestroy() {
    this.recipeSub.unsubscribe();
  }

  onDelete(postId: string) {
    this.recipeService.deleteRecipe(postId).subscribe();
  }

}
