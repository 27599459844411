import { Pipe, Injectable, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterByCategory'
})

@Injectable()
export class FilterByCategory implements PipeTransform {
    transform(recipes:  {}, filter: string) {
        const allRecipes = [];
        for (const cat in recipes) {
            if (recipes.hasOwnProperty(cat)) {
                recipes[cat].forEach(element => {
                    allRecipes.push(element);
                });
            }
        }

        return allRecipes.filter(r => {
              return r.cardConfig.filter(f => f.category === filter).length > 0;
        });
    }
}
