import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/authentification/auth.service';
import { map, tap } from 'rxjs/operators';
import { ConfigService } from '../../core/services/config/config.service';

@Injectable()
export class CardTypeGuard implements CanActivate, CanActivateChild {

  cardSlugs = [];

  constructor(private route: ActivatedRoute,
    private router: Router,
    private configService: ConfigService,) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkIfCardTypeExist(route);
  }

  canLoad(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): (boolean | Observable<boolean> | Promise<boolean>) {
    return this.checkIfCardTypeExist(route);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkIfCardTypeExist(route);
  }

  /**
   * on utilise un observalbe pour être sûr d'avoir récupérer la config. Sinon ça bug
   * @param route
   * @return Observable
   */
  checkIfCardTypeExist(route): Observable<boolean> {
    const cardType = route.paramMap.params['typeOfCard'];
    return this.configService.getConfig().pipe(map(config => {
      for (let card of config.cardTypes) {
        this.cardSlugs = [...this.cardSlugs, card["slug"]];
      }
      if (this.cardSlugs.includes(cardType)) {
        return true;
      } else {
        this.router.navigate(['/404']);
        return false;
      }
    }))
  }

}

