import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UltraAdminComponent } from './pages/ultra-admin/ultra-admin.component';
import { ConnexionSopediComponent } from './components/connexion-sopedi/connexion-sopedi.component';
import { ModalDeleteAdminComponent, SingleAdminComponent } from './components/single-admin/single-admin.component';
import { SharedModule } from '../../shared/shared.module';
import { AdminMenuComponent } from './components/admin-menu/admin-menu.component';
import { AdminListComponent } from './pages/admin-list/admin-list.component';
import { UniverseAdminComponent } from './pages/universe-admin/universe-admin.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatChipsModule } from '@angular/material/chips';



@NgModule({
  declarations: [UltraAdminComponent, ConnexionSopediComponent, SingleAdminComponent, ModalDeleteAdminComponent, AdminMenuComponent, AdminListComponent, UniverseAdminComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatDividerModule, MatChipsModule,
    RouterModule.forChild([
      { path: '', component: UltraAdminComponent, pathMatch: 'full' },
      { path: 'admin-list', component: UltraAdminComponent, pathMatch: 'full' },
      { path: 'universe', component: UniverseAdminComponent, pathMatch: 'full' },

    ])],
  entryComponents: [ModalDeleteAdminComponent]
})
export class UltraAdminModule { }
