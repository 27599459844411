import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../authentification/auth.service';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private authService: AuthService) { }

  /**
   * Intercept all request; 
   */
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const authToken = this.authService.getToken();
        // let headers = new Headers();
        // headers.append('Authorization', 'Bearer ' + authToken);
        // headers.append('Mode-univers', ');
        const authRequest = this.addToken(req, authToken);
        // return next.handle(authRequest);
        return next.handle(authRequest).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
                // console.log(error);

                return this.handle401Error(authRequest, next, error.error);
            } else {
                return throwError(error);
            }
        }));
    }


  /**
   * Return a clone of the request with the token
   */
    private addToken(req: HttpRequest<any>, token: string) {
        return req.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
                // ModeUniverse: 'ibis'
            }
            //    headers: req.headers.set('Authorization', 'Bearer ' + authToken)
        });
    }


    
  /** 
   * Refresh the token if this isRefreshing
   * else only get the value from subject
   * then add token to the request
   */
    private handle401Error(request: HttpRequest<any>, next: HttpHandler, error) {
        // console.log('handle 401 error', error);
        if (error.message === 'refresh_failed') {
            this.authService.logOut();
            this.isRefreshing = false;
            return next.handle(request);
        }
        if (!this.isRefreshing) {
            // console.log('!this.isRefreshing');
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            // console.log(window.localStorage.getItem('refresh'));

            return this.authService.refreshToken(window.localStorage.getItem('refresh')).pipe(
                switchMap((res: any) => {
                    // console.log('refreshed', res);

                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(res.token);
                    return next.handle(this.addToken(request, res.token));
                }));
        } else {
            // console.log('this.isRefreshing');

            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addToken(request, token));
                }));
        }

    }
}
