import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Recipe } from '../../../../../shared/models/recipe.model';
import { RecipesService } from 'src/app/core/services/recettes/recipes.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-plats-list',
  templateUrl: './plats-list.component.html',
  styleUrls: ['./plats-list.component.scss']
})
export class PlatsListComponent implements OnInit, OnDestroy {
  @Input() searchTerm = '';
  private recipeSub: Subscription;
  currentUrl: string;
  platsList: Recipe[] = [];
  constructor(public recipeService: RecipesService, private router: Router) { }
  ngOnInit() {
    this.currentUrl = this.router.url;
    this.recipeService.getAllRecipes().subscribe();

    this.recipeSub = this.recipeService.getRecipesUpdateListener()
      .subscribe((recipes: Recipe[]) => {
        if (recipes) {
          // console.log('recipes', recipes);
          this.platsList = recipes.filter(recipe => recipe.type === 'plat');
        }
      });
  }

  ngOnDestroy() {
    this.recipeSub.unsubscribe();
  }

  onDelete(postId: string) {
    this.recipeService.deleteRecipe(postId).subscribe();
  }

}
