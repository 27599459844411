import { Admin } from './../../../../shared/models/admin.model';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { AuthService } from '../../authentification/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProfilService {
  private urlApi = environment.urlApi;

  constructor(private _http: HttpClient, private authService: AuthService) { }

  patchProfilHotel(userData) {
    return this._http.patch<{ message, user }>(this.urlApi + 'user/hotel-profil', userData).pipe(
      map(data => data.user),
      tap(user => {
       // console.log('user PATCH PROFILE HOTEL : ', user)
      this.authService.setTypeHotel(user.hotelProfil.typeHotel);
        this.authService.setPriceMode(user.hotelProfil.price);
    }));

  }

  getAllAdmins() {
    return this._http.get<{ message, users: Admin[] }>(this.urlApi + 'user/all-admin');
  }

  getUser() {
    return this._http.get<{ message, user }>(this.urlApi + 'user').pipe(map(data => data.user));
  }

  patchFirstConnexion(firstConnexion) {
    return this._http.patch<{ message, user }>(this.urlApi + 'user/first-connexion', { firstConnexion });
  }

}
