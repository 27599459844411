import { Pipe, PipeTransform } from '@angular/core';
import { Actuality } from 'src/shared/models/actuality.model';

@Pipe({
  name: 'filterActu'
})
export class FilterActuPipe implements PipeTransform {

  transform(actus: Actuality[], filter: string): any {
    if (filter === undefined) {
        return actus;
    }
    return actus.filter(item => {
        return item['title'].toLowerCase().includes(filter.toLowerCase());
    });
}

}
