import { Actuality } from '../../../../shared/models/actuality.model';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ActuService {

  public actalities: Actuality[] = [];
  public spotlightActualities: Actuality[] = [];

  private actualitiesUpdated = new Subject<Actuality[]>();
  private spotlightActualitiesUpdated = new Subject<Actuality[]>();

  private apiUrl = environment.urlApi;
  constructor(private _http: HttpClient, private router: Router) { }

  getAllActualities() {
    return this._http.get<{ message: string, actualities: any }>(this.apiUrl + 'actualities')
      .pipe(map(resultat => {
        return resultat.actualities.map(elmt => {
          return {
            ...elmt
          };
        });
      })).pipe(tap(transformedData => {
        transformedData = transformedData.reverse();
        this.spotlightActualities = transformedData.filter(item => item.spotlight === 'Oui');
        this.actalities = transformedData;

        this.actualitiesUpdated.next([...this.actalities]);
        this.spotlightActualitiesUpdated.next([...this.spotlightActualities]);
      }, error => {
        console.log('erreur getAllActualities', error);
      }));

  }

  getActualitiesListener() {
    return this.actualitiesUpdated.asObservable();
  }

  getSpotlightActualitiesListener() {
    return this.spotlightActualitiesUpdated.asObservable();
  }
  getActuById(idReq: string) {
    return this._http.get<{
      _id: string, title: string, imagePath: string,
      content: string, datePosted: string, spotlight: string
    }>(this.apiUrl + 'actualities/' + idReq);
  }

  addActuality(title: string, content: string, image: File, datePosted: string, spotlight: string) {
    const postActuality = new FormData();

    postActuality.append('title', title);
    postActuality.append('content', content);
    postActuality.append('image', image, title.toLowerCase().split(' ').join('-').replace('\'', '-') + '-image');
    postActuality.append('datePosted', datePosted);
    postActuality.append('spothlight', spotlight);
    return this._http.post<{ message: string, actuality: any }>(this.apiUrl + 'actualities', postActuality).pipe(map(resultat => {
      const newActuality: Actuality = {
        _id: resultat.actuality._id,
        title: resultat.actuality.title,
        content: resultat.actuality.content,
        imagePath: resultat.actuality.imagePath,
        datePosted: resultat.actuality.datePosted,
        spotlight: resultat.actuality.spotlight
      };
      this.actalities.push(newActuality);
      this.actualitiesUpdated.next([...this.actalities]);
    }));

  }


  updateActuality(id: string, title: string,
    content: string, image: File | string, datePosted: string, spotlight: string) {

    let actualityData: Actuality | FormData;

    if (typeof (image) === 'object') {
      actualityData = new FormData();
      actualityData.append('_id', id);
      actualityData.append('title', title);
      actualityData.append('content', content);
      actualityData.append('image', image, title.toLowerCase().split(' ').join('-').replace('\'', '-'));
      actualityData.append('datePosted', datePosted);
      actualityData.append('spotlight', spotlight);

    } else {
      actualityData = {
        _id: id,
        title: title,
        content: content,
        imagePath: image,
        datePosted: datePosted,
        spotlight: spotlight

      };
    }


    return this._http.put<{ message: string, actuality: Actuality }>(this.apiUrl + 'actualities/' + id, actualityData)
      .pipe(map(res => {
        const updateActualities = [...this.actalities];
        const oldActualityIndex = updateActualities.findIndex(r => r._id === res.actuality._id);

        const actuality: Actuality = {
          _id: id,
          title: title,
          content: content,
          imagePath: 'res.imagePath',
          datePosted: datePosted,
          spotlight: spotlight
        };

        updateActualities[oldActualityIndex] = actuality;
        this.actalities = updateActualities;
        this.actualitiesUpdated.next([...this.actalities]);
        this.router.navigate(['/actualites/administration']);
      }));
  }


  onDeleteActuById(_id: string) {
    return this._http.delete(this.apiUrl + 'actualities/' + _id).pipe(tap(() => {
      const newActualities = this.actalities.filter(item => item._id !== _id);
      this.actalities = newActualities;
      this.actualitiesUpdated.next([...this.actalities]);
    }));
  }

  getLocalActuById(_id: string) {

    const actu = this.actalities.find(a => a._id === _id);
    if (!actu) {
      return;
    }
    return { ...actu };

  }


}
