import { Component, OnInit, Input, HostListener, ElementRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-single-plus-recipe',
  templateUrl: './single-plus-recipe.component.html',
  styleUrls: ['./single-plus-recipe.component.scss'],
  animations: [
    trigger('changeState', [
      state('initial', style({
        transform: 'translateX(-200%)'
      })),
      state('final', style({
        transform: 'translateX(0%)'
      })),
      transition('*=> *', animate('800ms ease-in-out')),
    ])
  ]
})
export class SinglePlusRecipeComponent implements OnInit {
  @Input() textBonus: string;
  @Input() simplifiedView = false;
  currentState = 'initial';

  constructor(public el: ElementRef) { }
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const scrollPosition = window.pageYOffset;


    if (scrollPosition >= 1300) {
      this.currentState = 'final';
    } else {
      this.currentState = 'initial';
    }

  }
  ngOnInit() {
  }

}
