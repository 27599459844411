import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '../authentification/auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const isAuth = this.authService.getAuth();
        const universe = this.authService.getUniverse();
        if (!isAuth && universe === 'marge') {
            this.router.navigate(['/connexion']);
        }
        return true;
    }


    canLoad(): (boolean | Observable<boolean> | Promise<boolean>) {
        const isAuth = this.authService.getAuth();
        const universe = this.authService.getUniverse();
        // console.log('universe', this.authService.getUniverse());
        if (!isAuth && universe === 'marge') {
            this.router.navigate(['/connexion']);
        }
        return true;
    }

    //     canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):  boolean {
    //         const isAuth = this.authService.getAuth();
    //         if (!isAuth) {
    //             this.router.navigate(['/connexion']);
    //         }
    //         return true;
    //    }

}
