import { Component, OnInit, Input } from '@angular/core';
import { AdminService } from '../../../../core/services/admin/admin.service';
import { AuthService } from 'src/app/core/authentification/auth.service';

@Component({
  selector: 'app-thumbnail-user',
  templateUrl: './thumbnail-user.component.html',
  styleUrls: ['./thumbnail-user.component.scss']
})
export class ThumbnailUserComponent implements OnInit {
  @Input() user: any;
  showModal = false;
  mailAdmin;
  isMarge = false;
  constructor(private adminService: AdminService, private authService: AuthService) { }

  ngOnInit() {
    //console.log(this.user);
    this.mailAdmin = this.authService.getEmail();
    if (this.mailAdmin.includes('margecommunication.com')) {
      this.isMarge = true;
    }
  }

  onDeleteUser(id: string) {
    this.adminService.deleteUser(id).subscribe();
  }

  sendWelcomeMail() {
    this.authService.sendMail(this.user.email, 'welcome').subscribe((res) => {
      if (res.message === 'error') {
        // this.displayError = true;
        // this.displaySuccess = false;
      } else {
        // this.displaySuccess = true;
        // this.displayError = false;
      }

    });
  }

  sendResetPwdMail() {
    this.authService.sendMail(this.user.email).subscribe((res) => {
      if (res.message === 'error') {
        // this.displayError = true;
        // this.displaySuccess = false;
      } else {
        // this.displaySuccess = true;
        // this.displayError = false;
      }

    });
  }

}
