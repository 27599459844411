import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterText',
    pure: false
})
export class MyFilterText implements PipeTransform {
    transform(users: any[], userMail: string): any {
        if (userMail === undefined) {
            return users;
        }
        return users.filter(item => {
            return item['email'].toLowerCase().includes(userMail.toLowerCase());
        });
    }
}
