import { Pipe, PipeTransform } from '@angular/core';
import { Recipe } from 'src/shared/models/recipe.model';

@Pipe({
    name: 'filterRecipes',
    pure: false
})
export class RecipesFilter implements PipeTransform {
    transform(recipes: Recipe[], filter: string): any {
        if (filter === undefined) {
            return recipes;
        }
        return recipes.filter(item => {
            return item['title'].toLowerCase().includes(filter.toLowerCase());
        });
    }
}
