import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Card } from '../../../../shared/models/card.model';
import { environment } from './../../../../environments/environment';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CardHttpService {

  constructor(private _http: HttpClient) {
  }

  private urlApi = environment.urlApi;

  /**
 * get from the api the card structure depending on sub universe et card type
 * @param subUniverse ex : gourmetbar
 * @param cardType ex: restaurant, bar, room
 */
  getJsonCard<Card>(cardType, subUniverse) {
    return this._http.get<{ message: String; card: Card }>(`${this.urlApi}cardconfigs/byslug/${cardType}_${subUniverse}`)
  }

  getJsonCardId<String, CardConfig>(id) {
    return this._http.get<{ message: String; card: CardConfig }>(`${this.urlApi}cardconfigs/${id}`);
  }

  getAllUniverseCards() {
    return this._http.get<{ message: String; cards: [Card] }>(`${this.urlApi}cardconfigs`).pipe(map(res => res.cards))
  }

  getCardsModelsByType(type) {
    return this._http.get<{ message: string, cards: Card[] }>(this.urlApi + 'cards/models/type/' + type)
  }

  getCardById(id) {
    return this._http.get<{ message: string, card: Card }>(this.urlApi + 'cards/' + id)
  }

  getMyCards() {
    return this._http.get<{ message: string, cards: Card[] }>(this.urlApi + 'cards')
  }
  /**
   * post card to api
   * @param title card title
   * @param englishVersion yes or no (boolean)
   */
  postCard(newListe) {
    return this._http.post(this.urlApi + 'cards', newListe)
  }

  saveMyCard(recipes, title, type, englishVersion) {
    return this._http.post(this.urlApi + 'cards', { recipes, title, type, englishVersion });
  }

  switchDraftCard(idCard, isModelToUser) {
    return this._http.patch(this.urlApi + 'cards/' + idCard, { isModelToUser })
  }

  switchListCard(idCard, toPrint) {
    return this._http.patch(this.urlApi + 'cards/' + idCard, { toPrint })
  }

  patchCardById(idCard, title, englishVersion) {
    const tmp = JSON.parse(localStorage.getItem('construction-card'));
    const list = { ...tmp, title, englishVersion };
    return this._http.patch(this.urlApi + 'cards/' + idCard, list)
  }

  deleteCardById(cardID) {
    return this._http.delete<{}>(this.urlApi + 'cards/' + cardID)
  }

}
