import { Router, NavigationEnd } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CartesService } from 'src/app/core/services/cartes/cartes.service';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';

import { AuthService } from '../authentification/auth.service';
import { Auth0Service } from '../../core/services/auth0/auth0.service';
import { Subscription } from 'rxjs';
import UserStatus from 'src/shared/enum/UserStatus.enum';
import UserUniverse from 'src/shared/enum/UserUniverse.enum';
import { ConfigService } from '../../core/services/config/config.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  searchField: FormControl;
  userAuthenticated = false;
  userStatus: string;
  USER_STATUS = UserStatus;
  USER_UNIVERSE = UserUniverse;
  universe;
  isPwa = false;
  private authListenerSub: Subscription;
  email: string;
  typeHotel;

  idUser?: string;
  universesAvailable?: string[];

  infoUserSub: Subscription;
  isChangingUniverse = false;

  cardLabels;
  enableProfil = false;

  /**
   * isChangingUniverse is used to show a spinner on the header while changing universe
   * before the reload (if i'm correct)
   * // a checker : y'a une histoire de localstorage('isChangingUniverse')
   * // y'a peut être moyen de faire sans
   */
  constructor(private authService: AuthService,
    private auth0Service: Auth0Service,
    private carteService: CartesService,
    private dialog: MatDialog,
    private configService: ConfigService,
    private router: Router) {

  }
  askToChangeUniverse() {
    const dialog = this.dialog.open(ChangeUniverseComponent, {
      panelClass: 'full-width-dialog',
      disableClose: true,
      data: {
        universes: this.universesAvailable,
        userId: this.idUser,
        currentUniverse: this.universe
      }
    });

    /**
     * after the modal is closed
     * we open a new loading modal
     * (juste displaying a spinner) for 1200ms
     * then we reload the page
    */
    dialog.afterClosed().subscribe(resAfterClose => {
      if (resAfterClose) {
        if (resAfterClose.newUniverse !== 'marge') {
          document.body.style.cursor = 'wait';
          this.router.navigate(['accueil']);

          this.dialog.open(ModalLoadingComponent, { width: '300px' });
          this.isChangingUniverse = true;

          setInterval(() => {
            location.reload();
            localStorage.setItem('isChangingUniverse', 'true');
            document.body.style.cursor = 'default';

          }, 1200);

        } else {
          this.router.navigate(['ultra']);
        }
      }
    });
  }
  ngOnInit() {
    // console.log('ng on init');
    if (!localStorage.getItem('isChangingUniverse')) {
      localStorage.setItem('isChangingUniverse', 'false');
    } else if (localStorage.getItem('isChangingUniverse') === 'true') {
      this.isChangingUniverse = true;
    }

    this.configService.getConfig().subscribe(config => {
      if (config) {
        console.log(config);
        this.cardLabels = config.cardTypes;
        //console.log(this.cardLabels);
      }
    })

    this.authService.getSecureStatus(localStorage.getItem('email')).subscribe();

    this.infoUserSub = this.authService.getInfoUserListener().subscribe(res => {
      if (res) {
        this.universe = res.universe;
        this.email = res.email;
        this.userStatus = res.status;
        this.idUser = res.idUser;
        this.universesAvailable = res.universesAvailable;
        this.typeHotel = res.typeHotel;

        this.isChangingUniverse = false;
        localStorage.setItem('isChangingUniverse', 'false');

        /**
         * If the user doesn't belong
         * to any universe, we ask him to choose one
         * within a new modal that we open in askToChangeUniverse()
        */
        if (res.universe === 'none') {
          this.askToChangeUniverse();
        }
      }

    });

    if (window.matchMedia('(display-mode: standalone)').matches) {
      this.isPwa = true;
    }
    this.userAuthenticated = this.authService.getAuth();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const isCreate = event.urlAfterRedirects.includes('cartes');
        const isRecap = event.urlAfterRedirects.includes('recapitulatif') && event.urlAfterRedirects.includes('cartes');
        const isEdit = event.urlAfterRedirects.includes('edit') && event.urlAfterRedirects.includes('cartes');
        console.log('COUCOU ');

        if (!isCreate && !isRecap && !isEdit) {
          localStorage.removeItem('construction-card');
          this.carteService.resetConstructionCard();
        } else {
          this.carteService.initConstructionCard();
        }
      }
    });
    console.log('rrrrrrrrrrr', this.universe);
    //console.log(this.carteService.getCardFromLocalStorage());
  }

  onLogout() {
    this.authService.logOut();
    this.carteService.resetConstructionCard();
    window.localStorage.removeItem('construction-card');
    localStorage.removeItem('construction-card');
    localStorage.removeItem('typeHotel');
  }

  ngOnDestroy() {
    if (this.authListenerSub) {
      this.authListenerSub.unsubscribe();
    }
    if (this.infoUserSub) {
      this.infoUserSub.unsubscribe();
    }
  }
  goToSopedi() {
    this.auth0Service.getUrlRedirection().pipe(take(1)).subscribe(res => {
      window.open(res.message.url, '_blank');
      console.log("RES redirection SOPEDI :", res);
    });
  }
}


/**
 * Modal to change universe
 */
@Component({
  selector: 'app-change-universe',
  templateUrl: 'change-universe.html',
  styleUrls: ['./change-universe.scss']
})
export class ChangeUniverseComponent {
  universe;
  hasUniverse = true;
  /**
   * If the user is already in an universe
   * he can close the modal, if not he can't
   */
  patchSub: Subscription;
  constructor(public dialogRef: MatDialogRef<ChangeUniverseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService) {
    this.universe = this.data.currentUniverse;
    if (this.universe === 'none') {
      this.hasUniverse = false;
    }
  }

  onCloseDialog() {
    this.dialogRef.close();
    if (this.patchSub) {
      this.patchSub.unsubscribe();
    }
  }

  onSubmit() {
    this.patchSub = this.authService.patchUniverse(this.universe, this.data.userId).subscribe(res => {
      this.dialogRef.close({ newUniverse: this.universe });
    });
  }

}




/**
 * Modal loading "changement d'univers en cours..."
 */
@Component({
  selector: 'app-modal-loading',
  templateUrl: 'modal-loading.component.html',
  styleUrls: ['./change-universe.scss']
})
export class ModalLoadingComponent {
  universe;
  hasUniverse = true;
  patchSub: Subscription;
  constructor(public dialogRef: MatDialogRef<ModalLoadingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService) {
  }

  onCloseDialog() {
    this.dialogRef.close();

  }




}

