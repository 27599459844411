import { Pipe, PipeTransform } from '@angular/core';
import { Recipe } from 'src/shared/models/recipe.model';

@Pipe({
    name: 'correctPrice',
    pure: false
})
export class CorrectPrice implements PipeTransform {
    transform(value: string) {

        // return value.replace(/\./g, ',').ToString("F");

    }
}
