import { Subscription } from 'rxjs';
import { Cocktail } from '../../../../shared/models/cocktail.model';
import { CocktailsService } from '../../../core/services/cocktails/cocktails.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-carousel-cocktails',
  templateUrl: './carousel-cocktails.component.html',
  styleUrls: ['./carousel-cocktails.component.scss']
})

export class CarouselCocktailsComponent implements OnInit, OnDestroy {
  allCocktails: Cocktail[] = [];
  responsiveOptions;
  getAllCocktailsSub: Subscription;
  getCocktailsListSub: Subscription;
  constructor(private cocktailService: CocktailsService) {
    this.responsiveOptions = [
      {
          breakpoint: '1024px',
          numVisible: 3,
          numScroll: 3
      },
      {
          breakpoint: '768px',
          numVisible: 2,
          numScroll: 2
      },
      {
          breakpoint: '560px',
          numVisible: 1,
          numScroll: 1
      }
  ];
   }

  ngOnInit() {
    this.getAllCocktailsSub = this.cocktailService.getAllCocktails().subscribe();
    this.getCocktailsListSub = this.cocktailService.getAllCocktailsListener().subscribe(res => {
      if (res) {
        this.allCocktails = res;
      }
     });
  }

  ngOnDestroy() {
    if (this.getAllCocktailsSub) {
      this.getAllCocktailsSub.unsubscribe();
    }
    if (this.getCocktailsListSub) {
      this.getCocktailsListSub.unsubscribe();
    }
  }

}
