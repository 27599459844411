import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { ActivatedRoute, ParamMap } from '@angular/router';
import { Recipe } from '../../../../../shared/models/recipe.model';

import { Subscription } from 'rxjs';
import { RecipesService } from '../../../../core/services/recettes/recipes.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AuthService } from 'src/app/core/authentification/auth.service';

import Universe from 'src/shared/enum/Universe.enum';
import { ConfigService } from '../../../../core/services/config/config.service';

@Component({
  selector: 'app-add-recipe',
  templateUrl: './add-recipe.component.html',
  styleUrls: ['./add-recipe.component.scss']
})

export class AddRecipeComponent implements OnInit, OnDestroy {
  UNIVERSE = Universe;
  recipeForm: FormGroup;

  blocForm: FormGroup;
  categoryForm: FormGroup;

  imagePreview: string;
  imageHDPreview: string;
  videoReadyToExport = false;
  arrayIngredientsMin = [];
  arrayIngredientsMaj = [];
  arrayEquipSpec = [];
  imageChangedEvent: any = '';
  myHdImage: File;
  technicFileNamePreview = '';
  mode = 'create';
  pdfFileName = '';
  videoFileName = '';
  imageFileName = '';
  typeOfMyFile: string;
  hdImagePath: string;
  recipeID: string;
  recipe: Recipe;
  // public arrayf = ['loul', 'lil'];
  routeSub: Subscription;
  percentUpdated: Number = 0;
  pictoToPick = ['Vegan', 'Végétarien', 'De Saison', 'Authentique', 'Coup de coeur', 'Sain', 'Incontournable'];
  pictoToSend = [];

  pdfReadyToGo = false;
  croppedImage: any = '';
  croppedFile: File;
  emptyFile: File = null;
  formDataToSend = new FormData();
  isSeasons = [{ name: 'Printemps', checked: false },
  { name: 'Été', checked: false },
  { name: 'Automne', checked: false },
  { name: 'Hiver', checked: false }
  ];
  arrayOfSeason = [];
  universe;

  isLoading = false;

  recipeLabels;

  constructor(public recipeService: RecipesService,
    public route: ActivatedRoute,
    private el: ElementRef,
    private configService: ConfigService,
    private authService: AuthService) { }


  onAddPick(pick: string) {
    this.pictoToSend.push(pick);
    this.pictoToPick = this.pictoToPick.filter(item => item !== pick);
  }
  onDelete(pick) {
    this.pictoToPick.push(pick);
    this.pictoToSend = this.pictoToSend.filter(item => item !== pick);
  }

  imageLoaded() {
    // show cropper
  }
  loadImageFailed() {
    // show message
  }


  get cardConfig() {
    return this.recipeForm.get('cardConfig') as FormArray;
  }


  ngOnInit() {
    //console.log('Add recipe component');
    this.configService.getConfig().subscribe(config => {
      console.log(config);
      if (config) { this.recipeLabels = config.recipeLabels }
    })
    this.recipeForm = new FormGroup({
      cardConfig: new FormArray([
        new FormGroup({
          cardConfigId: new FormControl('', {}),
          cardType: new FormControl('', {}),
          bloc: new FormControl('', {}),
          category: new FormControl('', {}),
          must: new FormControl(false, {}),
          subuniverse: new FormControl('', {})
        })], {}),
      // subUniverse: new FormControl('universe2'),
      title: new FormControl('', {
        validators: [Validators.required, Validators.minLength(3)]
      }),
      description: new FormControl('', {
        // validators: [Validators.required, Validators.minLength(3)]
      }),
      type: new FormControl('', {
        validators: [Validators.required]
      }),

      image: new FormControl('',
        {
          validators: [Validators.required],
          // asyncValidators: mimeType
        }),
      technicFile: new FormControl('',
        {
          // validators: [Validators.required],
          // asyncValidators: mimeType
        }),

      ingredientsMin: new FormControl('',
        {
          // validators: [Validators.required],
        }),
      ingredientsMaj: new FormControl('',
        {
          // validators: [Validators.required],
        }),
      difficulty: new FormControl('',
        {
          // validators: [Validators.required],
        }),
      time: new FormControl('',
        {
          // validators: [Validators.required],
        }),
      textBonus: new FormControl('',
        {
          // validators: [Validators.required],
        }),
      premiumPrice: new FormControl('',
        {
          // validators: [Validators.required],
        }),
      price: new FormControl('',
        {
          // validators: [Validators.required],
        }),
      reference: new FormControl('', {
        // validators: [Validators.required, Validators.minLength(3)]
      }),

      englishForm: new FormGroup({
        title: new FormControl(''),
        description: new FormControl(''),
        ingredients: new FormControl(''),
        subtitle: new FormControl('')

      }),

      equipSpec: new FormControl('', {}),
      video: new FormControl('', {}),
      saisons: new FormControl('', {}),
      subUniverse: new FormControl({validators: [Validators.required],}),

    });

    this.authService.getCurrentUniverseListener().subscribe(universe => {
      if (universe) {
        this.universe = universe;
        // console.log('on est la', universe);
        // TODO UNIVERSE NOVOTEL
        if (this.universe === 'novotel') {
          this.recipeForm.patchValue({ subUniverse: 'universe1' });
        }
      }
    });


    // this.recipeForm.reset();
    // this.recipeForm.setValue({subUnivers: 'universe1'});

    this.routeSub = this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('recipeID')) {
        this.mode = 'edit';
        this.recipeID = paramMap.get('recipeID');
        this.recipeService.getRecipeById(this.recipeID).subscribe(res => {
          this.recipe = res;
          console.log('this recipe', this.recipe);
          this.pictoToSend = res.pictos;
          this.arrayIngredientsMaj = res.arrayIngMaj;
          this.arrayIngredientsMin = res.arrayIngMin;
          this.arrayOfSeason = res.saisons;

          this.arrayEquipSpec = res.equipSpec;
          this.pictoToSend = res.pictos;

          this.pictoToPick = this.getDiffArray(res.pictos);

          // this.imagePreview = this.recipe.imagePath;

          const { title, type, difficulty, time, textBonus, price, reference, saisons, premiumPrice, description } = this.recipe;

          this.recipeForm.patchValue({
            title,
            type,
            description: description === 'null' ? '' : description,
            difficulty,
            time,
            textBonus,
            price,
            premiumPrice,
            reference,
            saisons,
            'image': this.recipe.imagePath,
            'ingredientsMin': this.recipe.arrayIngMin,
            'ingredientsMaj': this.recipe.arrayIngMaj,
            'equipSpec': this.recipe.equipSpec,
            'video': this.recipe.videoPath,
            'technicFile': this.recipe.technicFilePath,
            subUniverse: this.recipe.subUniverse
          });

          if (this.recipe.englishVersion) {
            this.recipeForm.get('englishForm').setValue(this.recipe.englishVersion);
          } else {
            this.recipeForm.get('englishForm').setValue({ title: '', description: '', ingredients: [], subtitle: '' });
          }
          if (this.recipe.subtitle) {
            this.recipeForm.patchValue({ 'subtitle': this.recipe.subtitle });
          }

          const arr = <FormArray>this.recipeForm.controls.cardConfig;
          arr.controls = [];

          res.cardConfig.forEach(element => {
            this.cardConfig.push(
              new FormGroup({
                cardConfigId: new FormControl(element.cardConfigId, {}),
                cardType: new FormControl(element.cardType, []),
                category: new FormControl(element.category, []),
                bloc: new FormControl(element.bloc, []),
                must: new FormControl(element.must, []),
                subuniverse: new FormControl(element.subuniverse, {})

              })
            );
          });

          this.hdImagePath = this.recipe.hdImagePath;
          this.initiateMySeasons(this.recipe.saisons);
        });
      } else {
        this.mode = 'create';
        this.recipeID = null;
      }
    });

  }

  onSaveRecipe(event: Event) {
    event.preventDefault();
    if (this.recipeForm.invalid) {
      return;
    }
    this.isLoading = true;
    // technicFile videoPicker filePicker
    const inputElTechnicFile: HTMLInputElement = this.el.nativeElement.querySelector('#technicFile');
    const inputElVideoPicker: HTMLInputElement = this.el.nativeElement.querySelector('#videoPicker');
    const inputElfilePicker: HTMLInputElement = this.el.nativeElement.querySelector('#imagePicker');

    const fileCount1: number = inputElTechnicFile.files.length;
    const fileCount2: number = inputElVideoPicker.files.length;
    const fileCount3: number = inputElfilePicker.files.length;
    // const formData = new FormData();

    if (fileCount1 > 0) {
      for (let i = 0; i < fileCount1; i++) {
        this.formDataToSend.append('technicFile', inputElTechnicFile.files.item(i));
      }
    } else {
      this.formDataToSend.append('technicFilePath', this.recipe.technicFilePath);
    }

    if (fileCount2 > 0) {
      for (let i = 0; i < fileCount2; i++) {
        this.formDataToSend.append('video', inputElVideoPicker.files.item(i));
      }
    } else if (this.recipe && this.recipe.videoPath) {
      this.formDataToSend.append('videoPath', this.recipe.videoPath);
    } else {
      this.formDataToSend.append('video', null);
    }


    if (this.croppedFile) {
      this.formDataToSend.append('image', this.croppedFile);
    } else {
      this.formDataToSend.append('imagePath', this.recipe.imagePath);
    }
    if (fileCount3 > 0) {
      for (let i = 0; i < fileCount3; i++) {
        this.formDataToSend.append('hdImage', inputElfilePicker.files.item(i));
      }
    } else {
      this.formDataToSend.append('hdImagePath', this.recipe.hdImagePath);
    }

    //if (this.universe === 'novotel') {
    if(this.recipeLabels.length > 1) {
      if (typeof this.recipeForm.value.subUniverse !== 'string') {
        this.recipeForm.value.subUniverse = 'noconcept'
      }
      this.formDataToSend.append('subUniverse', this.recipeForm.value.subUniverse);
      
    }

      this.formDataToSend.append('premiumPrice', this.recipeForm.value.premiumPrice);

    //}
    // console.log('tu envoies, ', this.arrayIngredientsMaj);
    this.formDataToSend.append('arrayIngMin', JSON.stringify(this.arrayIngredientsMin));
    this.formDataToSend.append('arrayIngMaj', JSON.stringify(this.arrayIngredientsMaj));
    this.formDataToSend.append('equipSpec', JSON.stringify(this.arrayEquipSpec));
    this.formDataToSend.append('pictos', JSON.stringify(this.pictoToSend));
    this.formDataToSend.append('saisons', JSON.stringify(this.arrayOfSeason));
    this.formDataToSend.append('englishVersion', JSON.stringify(this.recipeForm.value.englishForm));

    this.formDataToSend.append('cardConfig', JSON.stringify(this.recipeForm.value.cardConfig));
    this.formDataToSend.append('title', this.recipeForm.value.title);
    // this.formDataToSend.append('subtitle', this.recipeForm.value.subtitle);
    this.formDataToSend.append('description', this.recipeForm.value.description);

    this.formDataToSend.append('type', this.recipeForm.value.type);
    this.formDataToSend.append('difficulty', this.recipeForm.value.difficulty);
    this.formDataToSend.append('time', this.recipeForm.value.time);
    this.formDataToSend.append('textBonus', this.recipeForm.value.textBonus);
    this.formDataToSend.append('price', this.recipeForm.value.price);
    this.formDataToSend.append('reference', this.recipeForm.value.reference);

  console.log('data to send : ', this.formDataToSend);
    if (this.mode === 'create') {
      this.recipeService.addRecipe(
        this.formDataToSend
      ).subscribe(() => {
        this.isLoading = false;
      });

    } else {

      this.recipeService.updateRecipe(
        this.recipeID,
        this.formDataToSend
      ).subscribe(() => {
        this.isLoading = false;
      });
    }
    return false;
  }

  getFileObjectFromUrl(filePathOrUrl, cb) {
    this.getFileBlob(filePathOrUrl, (blob) => {
      cb(this.blobToFile(blob, 'default-pic.jpg'));
    });
  }

  getFileBlob(url, cb) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.addEventListener('load', () => {
      cb(xhr.response);
    });
    xhr.send();
  }

  blobToFile(blob, name) {
    blob.lastModifiedDate = new Date();
    blob.name = name;
    return blob;
  }

  onTechnicFilePicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.recipeForm.patchValue({ technicFile: file });
    this.recipeForm.get('technicFile').updateValueAndValidity();
    const reader = new FileReader();
    const reader2 = new FileReader();
    reader.onload = () => {
      this.pdfFileName = file.name;
      this.pdfReadyToGo = true;
    };

    reader.readAsDataURL(file);

  }




  dataURItoBlob(dataURI) {

    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
  onImagePicked(event: Event) {
    this.imageChangedEvent = event;
  }

  chooseImage(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.myHdImage = file;
    const reader = new FileReader();
    reader.onload = () => {
      this.imageHDPreview = reader.result.toString();
      this.imageFileName = file.name;
    };
    reader.readAsDataURL(this.myHdImage);
  }


  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    const blob = this.dataURItoBlob(this.croppedImage);
    this.croppedFile = new File([blob], 'fileName.jpeg', { type: 'image/jpeg' });
    this.recipeForm.patchValue({ image: this.croppedFile });
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result.toString();
    };
    reader.readAsDataURL(this.croppedFile);
  }

  onVideoPicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.recipeForm.patchValue({ video: file });
    this.recipeForm.get('video').updateValueAndValidity();
    const reader = new FileReader();
    reader.onloadend = () => {
      this.videoReadyToExport = true;
      this.videoFileName = file.name;
    };
    reader.readAsDataURL(file);
  }

  onAddIngredientsMin(iing) {
    this.arrayIngredientsMin.push(iing.value);

    // if (this.mode === 'edit') {
    //   this.recipe.arrayIngMin.push(iing.value);
    // } else {
    //   this.arrayIngredientsMin.push(iing.value);
    // }
  }

  onRemoveIngMin(value) {
    this.arrayIngredientsMin = this.arrayIngredientsMin.filter(item => item !== value);

    // if (this.mode === 'edit') {
    //   this.recipe.arrayIngMin = this.recipe.arrayIngMin.filter(item => item !== value);
    // } else {
    //   this.arrayIngredientsMin = this.arrayIngredientsMin.filter(item => item !== value);
    // }
  }

  onAddIngredientsMaj(iing) {
    this.arrayIngredientsMaj.push(iing.value);
    // if (this.mode === 'edit') {
    //   this.recipe.arrayIngMaj.push(iing.value);
    // } else {
    //   this.arrayIngredientsMaj.push(iing.value);
    // }
  }
  onRemoveIngMaj(value) {
    // if (this.mode === 'edit') {
    //   this.recipe.arrayIngMaj = this.recipe.arrayIngMaj.filter(item => item !== value);
    // } else {
    //   this.arrayIngredientsMaj = this.arrayIngredientsMaj.filter(item => item !== value);
    // }
    this.arrayIngredientsMaj = this.arrayIngredientsMaj.filter(item => item !== value);

    // console.log('tu remove', value, this.arrayIngredientsMaj);
  }
  onAddEquipSpec(ing) {
    this.arrayEquipSpec.push(ing.value);
    // if (this.mode === 'edit') {
    //   this.recipe.equipSpec.push(ing.value);
    // } else {
    //   this.arrayEquipSpec.push(ing.value);
    // }
  }

  onRemoveEquipSpec(value) {
    this.arrayEquipSpec = this.arrayEquipSpec.filter(item => item !== value);
    // if (this.mode === 'edit') {
    //   this.recipe.equipSpec = this.recipe.equipSpec.filter(item => item !== value);
    // } else {
    //   this.arrayEquipSpec = this.arrayEquipSpec.filter(item => item !== value);
    // }
  }

  getDiffArray(array) {
    const a = [], diff = [];

    for (let i = 0; i < this.pictoToPick.length; i++) {
      a[this.pictoToPick[i]] = true;
    }

    for (let i = 0; i < array.length; i++) {
      if (a[array[i]]) {
        delete a[array[i]];
      } else {
        a[array[i]] = true;
      }
    }

    // tslint:disable-next-line:forin
    for (const k in a) {
      diff.push(k);
    }

    return diff;
  }


  /**************** GESTION DES SAISONS ****************/

  initiateMySeasons(saisons: string[]) {
    saisons.forEach(singleSaison => {
      this.isSeasons.forEach(elmt => {
        if (elmt.name === singleSaison) {
          elmt.checked = true;
        }
      });
    });

  }

  onChange(index: number) {
    this.isSeasons[index].checked = !this.isSeasons[index].checked;
    this.arrayOfSeason = [];
    this.isSeasons.forEach(elmt => {
      if (elmt.checked) {
        this.arrayOfSeason.push(elmt.name);
      }
    });
  }

  /****************** FIN DE GESTION DES SAISONS **********************/

  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }
}
