import { map } from 'rxjs/operators';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '../authentification/auth.service';

@Injectable()
export class MargeGuard implements CanLoad {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const email = this.authService.getEmail();

    return this.authService.getSecureStatus(email).pipe(map(
      res => {
        if (res.universe === 'marge') {
          return true;
        } else {
          this.router.navigate(['/accueil']);

        }
      }
    ));
  }


  canLoad(): (boolean | Observable<boolean> | Promise<boolean>) {
    const email = this.authService.getEmail();

    return this.authService.getSecureStatus(email).pipe(map(
      res => {
        if (res.universe === 'marge') {
          return true;
        } else {
          return false;
        }
      }
    ));
  }



}
