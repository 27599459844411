import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Recipe } from '../../../../../shared/models/recipe.model';
import { Subscription } from 'rxjs';
import { RecipesService } from 'src/app/core/services/recettes/recipes.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-entrees-list',
  templateUrl: './entrees-list.component.html',
  styleUrls: ['./entrees-list.component.scss']
})
export class EntreesListComponent implements OnInit, OnDestroy {
  private recipeSub: Subscription;
  currentUrl: string;
  entreeList: Recipe[] = [];

  @Input() searchTerm = '';
  constructor(public recipeService: RecipesService, private router: Router) { }
  ngOnInit() {
    this.currentUrl = this.router.url;
    this.recipeService.getAllRecipes().subscribe();
    this.recipeSub = this.recipeService.getRecipesUpdateListener()
    .subscribe((recipes: Recipe[]) => {
      if (recipes) {
        this.entreeList = recipes.filter(recipe =>  recipe.type === 'entree');

      }

    });

}


  ngOnDestroy() {
    this.recipeSub.unsubscribe();
  }

  onDelete(postId: string) {
    this.recipeService.deleteRecipe(postId).subscribe();
  }

}
