import { MargeGuard } from './core/guards/marge.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { AdminGuard } from './core/guards/admin.guard';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { UniverseGuard } from './core/guards/universe.guard';
import { CardTypeGuard } from './core/guards/card.guard';


const routes: Routes = [
    { path: '', redirectTo: 'connexion', pathMatch: 'full' },
    { path: '404', component: PageNotFoundComponent },

    {
        path: 'ultra', canLoad: [MargeGuard],
        loadChildren: () => import('./modules/ultra-admin/ultra-admin.module').then(m => m.UltraAdminModule),
    }, // does not work, or does it ?

    {
        path: 'accueil',
        loadChildren: () => import('./modules/accueil/accueil.module').then(m => m.AccueilModule),
        canLoad: [AuthGuard]
    },

    {
        path: 'connexion',
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
    },

    {
        path: 'actualites',
        loadChildren: () => import('./modules/actualites/actualities.module').then(m => m.ActualitiesModuleModule),
        canLoad: [AuthGuard]
    },
    {
        path: 'recherche',
        loadChildren: () => import('./modules/recherche/research.module').then(m => m.ResearchModule),
        canLoad: [AuthGuard]
    },

    {
        path: 'outils',
        loadChildren: () => import('./modules/outils/outils.module').then(m => m.OutilsModule),
        canLoad: [AuthGuard]

    },
    {
        path: 'recettes',
        loadChildren: () => import('./modules/recettes/recipe.module').then(m => m.RecipeModule),
        canLoad: [AuthGuard]
    },
    {
        path: 'administration',
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
        canLoad: [AuthGuard, AdminGuard]
    },
    {
        path: 'profil',
        loadChildren: () => import('./modules/profil/profil.module').then(m => m.ProfilModule),
        canLoad: [AuthGuard]
    },
    {
        path: 'cartes',
        loadChildren: () => import('./modules/cartes/cartes.module').then(m => m.CartesModule),
        canLoad: [AuthGuard]
    },

    {
        path: 'cocktails',
        loadChildren: () => import('./modules/cocktails/cocktails.module').then(m => m.CocktailsModule),
        canLoad: [AuthGuard]
    },

    { path: '**', redirectTo: '/404' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
    providers: [AuthGuard, AdminGuard, UniverseGuard, CardTypeGuard, MargeGuard]
})
export class AppRoutingModule {


}
