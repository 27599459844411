export default abstract class ValidationMethods {

  /**
   * Permet de détecter si oui ou non on est sur la bonne méthode 
   * et si l'on doit l'utiliser pour savoir si le bloc est plein ou non
   * @param bloc 
   * @returns true si on a besoin de cette méthode, false sinon
   */
  abstract isMethods(bloc): Boolean
  /**
   * 
   * @param bloc // le bloc de la carte
   * @param type // type de recette si nécessaire
   * @param parent // bloc parent
   */
  abstract isFullBloc(bloc, type?, parent?): Boolean

};
