import { tap, map } from 'rxjs/operators';
import { environment } from './../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Drink } from './../../../../shared/models/drink.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BoissonsService {
  private apiUrl = environment.urlApi;
  private allDrinks: Drink[] = [];
  private allDrinksSubject = new BehaviorSubject<Drink[]>(null);
  constructor(private _http: HttpClient) { }


  getAllDrinksSujectListener() {
    return this.allDrinksSubject.asObservable();
  }

  getAllDrinks() {
    return this._http.get<{message: string, result: Drink[]}>(this.apiUrl + 'drinks')
      .pipe(tap(res => {
        this.allDrinks = res.result;
        this.allDrinksSubject.next([...this.allDrinks]);
      }));
  }

  getDrinksByCardType(type: string) {
    return this._http.get<any>(`${this.apiUrl}drinks/byCardType?type=${type}`).pipe(map(res => res.drinks));
  }

  postDrink(drinkData) {
    return this._http.post<{message: string, drink: Drink}>(this.apiUrl + 'drinks', drinkData)
    .pipe(tap((result) => {
      this.allDrinks.unshift(result.drink);
      this.allDrinksSubject.next([...this.allDrinks]);
    }));
  }

  patchDrink(drinkData, drinkId) {
    return this._http.patch<{message: string, drink: Drink}>(this.apiUrl + 'drinks/' + drinkId, drinkData)
    .pipe(tap((result) => {
      // console.log('resultat du patch', result);
      // this.allDrinks.unshift(result.drink);
      // this.allDrinksSubject.next([...this.allDrinks]);
    }));
  }

  getDrinkById(drinkId) {
    return this._http.get<{message: string , drink: Drink}>(this.apiUrl + 'drinks/' + drinkId).pipe(
      map(res => res.drink)
      );
  }

  deleteDrinkById(drinkId) {
    return this._http.delete<{message: string}>(this.apiUrl + 'drinks/' + drinkId).pipe(tap(
      () => {
        const index = this.allDrinks.findIndex(d => d._id === drinkId);
        this.allDrinks.splice(index, 1);
        this.allDrinksSubject.next([...this.allDrinks]);
      }
    ));
  }
}
