import { Pipe, PipeTransform } from '@angular/core';
import { Recipe } from 'src/shared/models/recipe.model';

@Pipe({
    name: 'alphabeticalOrder',
    pure: false
})
export class AlphabeticalOrder implements PipeTransform {
    transform(recipes: Recipe[]) {
     return recipes.sort(function(a, b) {
            if (a.title.toLocaleLowerCase() < b.title.toLocaleLowerCase()) { return -1; }
            if (a.title.toLocaleLowerCase() > b.title.toLocaleLowerCase()) { return 1; }
            return 0;
        });

    }
}
