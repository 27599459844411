import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/authentification/auth.service';
import { map } from 'rxjs/operators';
/**
 * how to use :
 * add a data object to route
 * ex: data: {universe: ['novotel']}
 */
@Injectable()
export class UniverseGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const email = this.authService.getEmail();
        return this.checkIfUniverse(email, route.data.universe);
    }

    canLoad(route: ActivatedRouteSnapshot): (boolean | Observable<boolean> | Promise<boolean>) {
        const email = this.authService.getEmail();
        return this.checkIfUniverse(email, route.data.universe);
    }


    checkIfUniverse(email: string, universe): Observable<boolean> {
        return this.authService.getSecureStatus(email).pipe(map(
            res => {
                // if (res.universe === 'marge'){
                //     this.router.navigate(['/ultra']);

                // }
                if (universe.includes(res.universe)) {
                    return true;
                } else {
                    this.router.navigate(['/accueil']);
                    return false;
                }
            }
        ));
    }

}

