import { Subscription } from 'rxjs';
import { CartesService } from 'src/app/core/services/cartes/cartes.service';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { CocktailsService } from '../../../core/services/cocktails/cocktails.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Cocktail } from 'src/shared/models/cocktail.model';
import { CarouselModule } from 'primeng/carousel';
import { FilesUrlPipe } from '../../pipes/files-url/files-url.pipe';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/core/authentification/auth.service';

@Component({
  selector: 'app-single-cocktail',
  templateUrl: './single-cocktail.component.html',
  styleUrls: ['./single-cocktail.component.scss'],
  providers: [FilesUrlPipe]
})
export class SingleCocktailComponent implements OnInit, OnDestroy {
  cocktailID: string;
  cocktail: Cocktail;
  toggleButton = true;
  @Input() simplifiedView;
  @Input() mode;
  addCocktailSub: Subscription;
  getCocktailSub: Subscription;
  getConstructionCardSub: Subscription;

  breadCrumbPath = [
    { title: 'cocktails', link: ['/cocktails/global'] }
  ];
  typeCard;
  constructor(private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private cocktailService: CocktailsService,
    private cardService: CartesService,
    private urlPipe: FilesUrlPipe,
    private authService: AuthService
  ) {

  }

  ngOnInit() {
    // console.log('COUCOU COCKTAIL');

    this.route.params.subscribe(params => {
      const typeURL = params['typeOfCard'];
      this.typeCard = typeURL;
    });

    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('cocktailID')) {
        this.cocktailID = paramMap.get('cocktailID');
        this.getCocktailSub = this.cocktailService.getCocktailById(paramMap.get('cocktailID')).subscribe(cocktail => {
          if (cocktail) {
            this.cocktail = cocktail;
            this.checkIfInCard();
          }
        });
      }
    }
    );
  }

  checkIfInCard() {
    // console.log(this.simplifiedView);

    if (this.simplifiedView) {
      this.getConstructionCardSub = this.cardService.getConstructionCardListener().subscribe(card => {
        if (card) {
          this.toggleButton = !this.cardService.checkIfElementIsInCard(card, this.cocktail);
          // console.log(this.toggleButton);

        } return;
      });
    }
  }

  cleanURL(oldURL): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.urlPipe.transform(oldURL));
  }

  onAddCocktail() {
    // console.log(this.cocktail);
    this.authService.getPriceModeListener().pipe(take(1)).subscribe(priceMode => {
      if (priceMode === 'premium') {
        this.cocktail.displayPrice = this.cocktail.premiumPrice;
      } else {
        this.cocktail.displayPrice = this.cocktail.price;
      }
      this.addCocktailSub = this.cardService.addElmtInCardRecipes(this.cocktail).subscribe();
    });

  }


  ngOnDestroy() {
    if (this.getCocktailSub) {
      this.getCocktailSub.unsubscribe();
    }
    if (this.getConstructionCardSub) {
      this.getConstructionCardSub.unsubscribe();
    }
    if (this.addCocktailSub) {
      this.addCocktailSub.unsubscribe();
    }
  }

}
