import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-single-breadcrumb',
  templateUrl: './single-breadcrumb.component.html',
  styleUrls: ['./single-breadcrumb.component.scss']
})
export class SingleBreadcrumbComponent implements OnInit {
  @Input() title: string;
  @Input() paths = [{ title: 'recettes', link: [] }];
  constructor() { }

  ngOnInit() {
  }

}
