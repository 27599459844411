import { AuthService } from 'src/app/core/authentification/auth.service';
import { Injectable } from '@angular/core';
import { Recipe } from 'src/shared/models/recipe.model';
import { Subject, BehaviorSubject, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class RecipesService {
  private urlApi = environment.urlApi;
  public recipes: Recipe[] = [];

  private recipesUpdated = new BehaviorSubject<Recipe[]>(null);
  typeOfMyFile: string;
  public progress: Number = 0;
  private percentFileUpdated = new Subject<Number>();
  public message: String = '';
  private isAddedRecipe = new Subject<Boolean>();
  loadingData = new Subject<Boolean>();
  constructor(private _http: HttpClient, private router: Router, private toastr: ToastrService, private authService: AuthService) { }

  getLoadingDataListener() {
    return this.loadingData.asObservable();
  }

  getHowToBuildMyCart() {
    return this._http.get<{ message: string }>(this.urlApi + 'recipes/guide-pdf');
  }

  getRecipesByCardType(type: string) {
    return this._http.get<any>(`${this.urlApi}recipes/byCardType?type=${type}`).pipe(map(res => res.recipes));
  }


  getAllRecipes() {
    return this._http.get<{ message: string, recipes: any }>(this.urlApi + 'recipes')
      .pipe(map((recipeData) => {
        return recipeData.recipes.map(recipe => {
          return { ...recipe };
        });
      }),
        tap(transformedData => {
          this.recipes = transformedData;
          this.recipesUpdated.next(transformedData);

        })
      );

  }

  getRecipesUpdateListener() {
    return this.recipesUpdated.asObservable();
  }
  percentFileUpdatedListenner() {
    return this.percentFileUpdated.asObservable();
  }

  isRecipeAddedUpdateListener() {
    return this.isAddedRecipe.asObservable();
  }


  addRecipe(fd: FormData) {
    // console.log('tu envoie fd', fd.get('title'), fd);
    return this._http.post<{ message: string, recipe: Recipe }>(this.urlApi + 'recipes/postRecipe', fd)
      .pipe(map(res => {
        console.log('res du post recipe', res);
        const newRecipe = { ...res.recipe, id: res.recipe['_id'] };
        this.recipes.push(newRecipe);
        this.recipesUpdated.next([...this.recipes]);
        this.isAddedRecipe.next(true);
        this.loadingData.next(false);

      }));
  }

  deleteRecipe(recipeId: string) {
    return this._http.delete<{ message: string }>(this.urlApi + 'recipes/' + recipeId).pipe(tap(() => {
      const updatedRecipes = this.recipes.filter(recipe => recipe._id !== recipeId);
      this.recipes = updatedRecipes;
      this.recipesUpdated.next([...this.recipes]);
    }));
  }

  getRecipeById(id: string) {
    // return this.authService.getCurrentUniverseListener().pipe(
    //   switchMap(res => {

    //       if (res !== 'novotel') {
    //         return this.getRecipes(null);
    //       } else {
    //         return this.authService.getPriceModeListener();
    //       }
    //   }),
    //   switchMap(priceMode => {
    //     return this.getRecipes(priceMode);

    //   })
    // );
    return this._http.get<Recipe>(this.urlApi + 'recipes/' + id);
    // return this.authService.getPriceModeListener().pipe(
    //   switchMap(priceMode => {
    //   if (priceMode) {
    //     return this._http.get<any>(this.urlApi + 'recipes/' + id).pipe(map(recette => {
    //       if (priceMode === 'standard') {
    //         return {...recette};
    //       } else {
    //         return {...recette, price: recette.premiumPrice};
    //       }
    //     }));
    //   }
    //   return this._http.get<Recipe>(this.urlApi + 'recipes/' + id);

    // }));
  }

  updateRecipe(id: string, recipeData: FormData) {
    return this._http.put<{ message: string, recipe: Recipe }>(this.urlApi + 'recipes/' + id, recipeData)
      .pipe(map(res => {
        console.log('res du put', res.recipe);
        const updateRecipes = [...this.recipes];
        const oldRecipeIndex = updateRecipes.findIndex(r => r._id === id);
        updateRecipes[oldRecipeIndex] = res['recipe'];
        this.recipes = updateRecipes;
        this.recipesUpdated.next([...this.recipes]);
        this.router.navigate(['/recettes/administration']);
      }));
  }



  getLocalRecipeById(recipeID: string) {
    return { ...this.recipes.find(r => r._id === recipeID) };
  }
}
