import { Component, OnInit, OnDestroy } from '@angular/core';
import { AdminService } from '../../../../core/services/admin/admin.service';
import { AuthService } from 'src/app/core/authentification/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent implements OnInit, OnDestroy {

  constructor(private adminService: AdminService, private authService: AuthService) { }
  arrayOfUsers = [];
  adminAndUser = [];
  searchTerm = '';
  showModal = false;
  private userSub: Subscription;
  ngOnInit() {
    this.adminService.getAllUser().subscribe();
    this.userSub = this.adminService.allUsersListen().subscribe(res => {
      res = res.filter(user => user['email'] !== this.authService.getEmail());
      this.arrayOfUsers = res;
    });
  }

  onAddInArray(elmt: string) {
    if (this.adminAndUser.indexOf(elmt) === -1) {
      this.adminAndUser.push(elmt);
    } else {
      this.adminAndUser.splice(this.adminAndUser.indexOf(elmt), 1);
    }
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

}
