import { Subscription } from 'rxjs';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Admin } from '../../../../../shared/models/admin.model';
import { Component, OnInit, Input, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AdminService } from '../../../../core/services/admin/admin.service';

@Component({
  selector: 'app-single-admin',
  templateUrl: './single-admin.component.html',
  styleUrls: ['./single-admin.component.scss']
})
export class SingleAdminComponent implements OnInit, OnDestroy {
  @Input() admin: Admin;
  @Input() universePossibles;
  @Output() hasDeleted = new EventEmitter<boolean>();

  arrayCheckUniverse = [];

  patchUserSub: Subscription;

  constructor(private adminService: AdminService, private snackBar: MatSnackBar, private dialog: MatDialog) { }

  ngOnInit() {
    this.admin.universesAvailable.forEach(univers => this.arrayCheckUniverse.push(univers));
  }

  isChecked(i) {
    if (this.arrayCheckUniverse.indexOf(this.universePossibles[i].value) > -1) {
      return true;
    }
    return false;
  }

  onSubmit() {
    const user: any = {};
    user.universesAvailable = this.arrayCheckUniverse;
    if (this.arrayCheckUniverse.length === 1) {
      user['universe'] = this.arrayCheckUniverse[0];
    }
    this.patchUserSub = this.adminService.patchUser(this.admin._id, user).subscribe(() => {
      this.openSnackBarSavedUser();
    });
  }

  openSnackBarSavedUser() {
    this.snackBar.open('Enregistré !', null, { duration: 3000 });
  }

  showModalDelete() {
    const dialogRef = this.dialog.open(ModalDeleteAdminComponent, {
      width: '350px',
      data: { admin: this.admin }
    });

    dialogRef.afterClosed().subscribe((data: { isDelete: string }) => {
      if (data.isDelete === 'true') {
        this.hasDeleted.emit(true);
      }
    });

  }
  onCheckboxChange(e) {
    if (e.checked) {
      this.arrayCheckUniverse.push(e.source.value);
    } else if (!e.checked) {
      this.arrayCheckUniverse = this.arrayCheckUniverse.filter(c => c !== e.source.value);
    }
  }

  ngOnDestroy() {
    if (this.patchUserSub) {
      this.patchUserSub.unsubscribe();
    }
  }
}



@Component({
  selector: 'app-modal-delete-admin',
  templateUrl: 'modal-delete-admin.html',
})
export class ModalDeleteAdminComponent {

  inputUser = '';
  constructor(
    public dialogRef: MatDialogRef<ModalDeleteAdminComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private adminService: AdminService) { }

  onNoClick() {
    this.dialogRef.close({ isDelete: 'false' });
  }

  onDeleteAdmin() {
    this.adminService.deleteAdmin(this.data.admin._id).subscribe(() => {
      this.dialogRef.close({ isDelete: 'true' });
    }, (error) => {
      this.dialogRef.close({ isDelete: 'false' });
    });
  }

}
