import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { HeaderComponent, ChangeUniverseComponent, ModalLoadingComponent } from './core/header/header.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from './core/interceptors/auth-interceptor.service';
import { RecipeModule } from './modules/recettes/recipe.module';
import { AdminModule } from './modules/admin/admin.module';

import { FooterComponent } from './core/footer/footer.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';

import { SharedModule } from './shared/shared.module';
import { CustomIconService } from './core/services/icons/CustomIconService';
import { MatButtonModule } from '@angular/material/button';

import { MatCheckboxModule } from '@angular/material/checkbox';
// import { ConnexionSopediComponent } from './modules/ultra-admin/components/connexion-sopedi/connexion-sopedi.component';
import { UltraAdminModule } from './modules/ultra-admin/ultra-admin.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
    ChangeUniverseComponent,
    ModalLoadingComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RecipeModule,
    AdminModule,
    SharedModule,
    MatButtonModule,
    MatCheckboxModule,
    UltraAdminModule
  ],
  entryComponents: [ChangeUniverseComponent, ModalLoadingComponent],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy},
    CustomIconService],
  bootstrap: [AppComponent]
})
export class AppModule { }
