import { RouterModule } from '@angular/router';
import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageUsersComponent } from './components/manage-users/manage-users.component';
import { InscriptionComponent } from './components/inscription/inscription.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ThumbnailUserComponent } from './components/thumbnail-user/thumbnail-user.component';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    RouterModule.forChild([
      { path: 'utilisateurs', component: ManageUsersComponent},
    ])
  ],
  declarations: [ManageUsersComponent, InscriptionComponent, ThumbnailUserComponent]
})
export class AdminModule { }

