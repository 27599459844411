import { Injectable } from '@angular/core';
import { containsObject } from '../../helper/objectHelper';
import { BehaviorSubject } from 'rxjs';
import CatRl from './CatRl';
import MinMax from './MinmaxHelper';

@Injectable({
  providedIn: 'root'
})
export class CardValidationService {
  constructor() {
  }

  methods = {
    catRl: new CatRl(),
    minMax: new MinMax()
  }
  cardState = {};
  cardStateSubject = new BehaviorSubject(null);
  cardStateReadyToUse = new BehaviorSubject<Boolean>(false);
  test = 0;

  /**
   * check if an element is alreayd in card
   * @param card the card
   * @param elmt recipe / drink / cocktail
   * @returns boolean
   */
  checkIfElementIsInCard(card, elmt) {
    // console.log(card, elmt.cardConfig);
    const typeHotel = localStorage.getItem('typeHotel');
    const config = elmt.cardConfig.find(c => (c.cardType === card.type && c.subuniverse.some(x => x === typeHotel) === true) || (c.cardType === card.cardType && c.subuniverse.some(x => x === typeHotel) === true));
    if (!config) {
      // console.log('!config', elmt);
      return;
    }
    const blocKey = config.bloc;
    const categoryKey = config.category;
    const blocToModify = card.blocs.find(el => el.slug === blocKey);
    // Sometimes the card type doesn't correspond to the thumbnail checkin' if its elmt is in the card .. rip
    if (!blocToModify) {
      return;
    }
    let categoriesToChange;
    try {
      //  Has trouble if there are no categories, sometimes (that mean I got the error and could not reproduce it. SAD)
      categoriesToChange = 'categories' in blocToModify ? blocToModify.categories : undefined;
    } catch (e) {
      // console.log('erreur est', e, elmt, card.blocs);
    }
    if (categoryKey === undefined || categoryKey === '') {
      if (!containsObject(elmt, blocToModify.recettes)) {
        return false;
      } return true;
    } else {
      const categoryToModify = categoriesToChange.find(c => c.slug === categoryKey);
      if ((categoryKey !== undefined || categoryKey !== '') && categoryToModify !== undefined) {
        if (containsObject(elmt, categoryToModify.recettes)) {
          return true;
        } return false;
      }
    }

  }

  cardStateListener() {
    return this.cardStateSubject.asObservable();
  }

  initCardState(blocs) {
    this.cardStateReadyToUse.next(false);
    this.cardState = {};
    blocs.forEach((bloc) => {
      if ('categories' in bloc && bloc.categories.length > 0) {
        bloc.categories.forEach((cat) => {
          this.isFullCategorieByIndex(cat, 'recettes', true);
          this.isMinRequiredCatByIndex(cat, 'recettes', true);
        });
      }
      this.isFullBlocByIndex(bloc, 'recettes', true);
      this.isMinRequiredBlocByIndex(bloc, 'recettes', true);

    });
    console.log(this.cardState);

    this.cardStateSubject.next({ ...this.cardState });
    this.cardStateReadyToUse.next(true);
  }

  totalFreeRecipe(recettes: []) {
    if (!recettes) { return; }
    const total = recettes.reduce((acc, val: any) => {
      if ('freeRecipe' in val && val.freeRecipe) {
        return acc + 1;
      } else {
        return acc;
      }
    }, 0);
    return total;
  }

  totalCatalogRecipe(recettes: []) {
    if (!recettes) { return; }
    const total = recettes.length - this.totalFreeRecipe(recettes);
    // console.log('total catalogue', total);
    return total;
  }

  totalBlocRecipe(bloc) {
    // console.log('bloc', bloc);
    let acc = 0;
    if ('recettes' in bloc) {
      acc += bloc.recettes.length;
    }
    if ('categories' in bloc) {
      // tslint:disable-next-line:no-shadowed-variable
      acc += bloc.categories.reduce((acc, val) => {
        return acc + val.recettes.length;
      }, 0);
    }
    // console.log('total bloc all', acc);
    return acc;
  }

  /**
   * Bloc + categories confondu
   * @param bloc un bloc de la carte
   */
  totalBlocFreeRecipe(bloc) {
    let acc = 0;
    if ('recettes' in bloc) {
      acc += this.totalFreeRecipe(bloc.recettes);
    }
    if ('categories' in bloc) {
      // tslint:disable-next-line:no-shadowed-variable
      acc += bloc.categories.reduce((acc, val) => {
        return acc + this.totalFreeRecipe(val.recettes);
      }, 0);
    }
    // console.log('total bloc all', acc);
    return acc;
  }

  totalBlocCatalogRecipe(bloc) {
    // console.log(bloc.title, this.totalBlocRecipe(bloc), this.totalBlocFreeRecipe(bloc));
    const total = this.totalBlocRecipe(bloc) - this.totalBlocFreeRecipe(bloc);
    return total;
  }
  /**
   * is a bloc full ?
   * @param bloc card bloc
   * @param iBloc index of car bloc
   * @param type string
   * @returns boolean
   */
  isFullBlocByIndex(bloc, type = 'all', init = false) {
    const displayPrice = 'displayPrice' in bloc ? bloc.displayPrice : false;
    this.cardState[bloc.slug] = {
      isFull: this.cardState[bloc.slug] && this.cardState[bloc.slug].isFull, // previous state
      isMinRequired: this.cardState[bloc.slug] && this.cardState[bloc.slug].isMinRequired, // previous state
      totalCatalog: this.totalBlocCatalogRecipe(bloc),
      totalFree: this.totalBlocFreeRecipe(bloc),
      displayPrice: displayPrice
    };

    // avec min max, tu auras besoin de détecter la méthode à utiliser avant
    //Thomas 12/02/21 : on choisit ici si on prend la methode Novotel ou Ibis Styles pour tester si le bloc est full ou pas
  let currentMethod;
    if(bloc.minmax_only && bloc.minmax_only === true){
      currentMethod = this.methods.minMax;
    } else {
      currentMethod = this.methods.catRl;
    }
    let isFull = currentMethod.isFullBloc(bloc, type)

    if (type !== 'recettes_libres') {
      this.cardState[bloc.slug]['isFull'] = isFull;
      //Add Thomas 12/02/21 to fix cardState not refresh
      this.cardStateSubject.next({ ...this.cardState });
      //Enf of add
    } else if (!init && type !== 'recettes_libres') {
      this.cardStateSubject.next({ ...this.cardState });
    }
    return isFull;
  }

  /**
   * need to know the current card
   ***** cat_fa : recettes catalogues facultatives
   ***** cat_obl : recettes catalogues obligatoire
   ***** rl_fa : recettes LIBRES facultatives
   ***** total_fa : total des recettes facultative
   * @param bloc current bloc
   * @param categorie current cat
   * @param iBloc index bloc
   * @param iCat index categorie
   * @returns boolean
   */
  isFullCategorieByIndex(cat, type = 'all', init = false) {
    const displayPrice = 'displayPrice' in cat ? cat.displayPrice : false;
    this.cardState[cat.slug] = {
      isFull: this.cardState[cat.slug] && this.cardState[cat.slug].isFull,
      totalCatalog: this.totalBlocCatalogRecipe(cat),
      totalFree: this.totalBlocFreeRecipe(cat),
      displayPrice: displayPrice
    };
    // avec min max, tu auras besoin de détecter la méthode à utiliser avant
    let currentMethod;
    if(cat.minmax_only && cat.minmax_only === true){
      currentMethod = this.methods.minMax;
    } else {
      currentMethod = this.methods.catRl;
    }
    let isFull = currentMethod.isFullBloc(cat, type);


    if (type !== 'recettes_libres') {
      this.cardState[cat.slug]['isFull'] = isFull;
      //Add Thomas 12/02/21 to fix cardState not refresh
      this.cardStateSubject.next({ ...this.cardState });
      //Enf of add
    } else if (!init && type !== 'recettes_libres') {
      this.cardStateSubject.next({ ...this.cardState });
    }
    return isFull;
  }

  isMinRequiredBlocByIndex(bloc, type = 'all', init = false) {
    const displayPrice = 'displayPrice' in bloc ? bloc.displayPrice : false;
    this.cardState[bloc.slug] = {
      isMinRequired: this.cardState[bloc.slug] && this.cardState[bloc.slug].isMinRequired, // previous state
      isFull: this.cardState[bloc.slug] && this.cardState[bloc.slug].isFull, // previous state
      totalCatalog: this.totalBlocCatalogRecipe(bloc),
      totalFree: this.totalBlocFreeRecipe(bloc),
      displayPrice: displayPrice
    };

    // avec min max, tu auras besoin de détecter la méthode à utiliser avant
    //Thomas 12/02/21 : on choisit ici si on prend la methode Novotel ou Ibis Styles pour tester si le bloc est full ou pas
    let currentMethod;
    if(bloc.minmax_only && bloc.minmax_only === true){
      currentMethod = this.methods.minMax;
    } else {
      currentMethod = this.methods.catRl;
    }
    const isMinRequired = currentMethod.isMinRequiredBloc(bloc, type);
    if (type !== 'recettes_libres') {
      this.cardState[bloc.slug]['isMinRequired'] = isMinRequired;
      //Add Thomas 12/02/21 to fix cardState not refresh
      this.cardStateSubject.next({ ...this.cardState });
      //Enf of add
    } else if (!init && type !== 'recettes_libres') {
      this.cardStateSubject.next({ ...this.cardState });
    }
    return isMinRequired;
  }

  isMinRequiredCatByIndex(cat, type = 'all', init = false) {
    const displayPrice = 'displayPrice' in cat ? cat.displayPrice : false;
    this.cardState[cat.slug] = {
      isMinRequired: this.cardState[cat.slug] && this.cardState[cat.slug].isMinRequired, // previous state
      isFull: this.cardState[cat.slug] && this.cardState[cat.slug].isFull, // previous state
      totalCatalog: this.totalBlocCatalogRecipe(cat),
      totalFree: this.totalBlocFreeRecipe(cat),
      displayPrice: displayPrice
    };

    // avec min max, tu auras besoin de détecter la méthode à utiliser avant
    //Thomas 12/02/21 : on choisit ici si on prend la methode Novotel ou Ibis Styles pour tester si le bloc est full ou pas
    let currentMethod;
    if(cat.minmax_only && cat.minmax_only === true){
      currentMethod = this.methods.minMax;
    } else {
      currentMethod = this.methods.catRl;
    }
    const isMinRequired = currentMethod.isMinRequiredBloc(cat, type);
    if (type !== 'recettes_libres') {
      this.cardState[cat.slug]['isMinRequired'] = isMinRequired;
      //Add Thomas 12/02/21 to fix cardState not refresh
      this.cardStateSubject.next({ ...this.cardState });
      //Enf of add
    } else if (!init && type !== 'recettes_libres') {
      this.cardStateSubject.next({ ...this.cardState });
    }
    //console.log('isMin Required : ', isMinRequired);
    return isMinRequired;
  }

}

