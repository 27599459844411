import { Component, OnInit, Input } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-anglais-form',
  templateUrl: './anglais-form.component.html',
  styleUrls: ['./anglais-form.component.scss']
})

export class AnglaisFormComponent implements OnInit {
  @Input() formData;
  @Input() mode; /** 'recipe', 'cocktail' */

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  ingredients = [];

  constructor() { }

  ngOnInit() {}

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      const curr = this.formData.get('englishForm').value.ingredients;
      this.formData.get('englishForm').patchValue({'ingredients': [...curr, value]});
    }
    if (input) {
      input.value = '';
    }
  }

  remove(ingredient) {
    const index = this.formData.get('englishForm').value.ingredients.indexOf(ingredient);
    if (index >= 0) {
      this.formData.get('englishForm').value.ingredients.splice(index, 1);
    }
  }

}
