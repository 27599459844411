import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/authentification/auth.service';
import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Recipe } from 'src/shared/models/recipe.model';
import Universe from 'src/shared/enum/Universe.enum';
import { ConfigService } from '../../../../core/services/config/config.service';

@Component({
  selector: 'app-single-top',
  templateUrl: './single-top.component.html',
  styleUrls: ['./single-top.component.scss']
})
export class SingleTopComponent implements OnInit {
  @Input() myRecipe: Recipe;
  @Input() simplifiedView = false;
  $modeRecipe;
  univers;
  UNIVERSE = Universe;

  listUniverse;
  universeConfig;

  constructor(private authService: AuthService,
    private configService: ConfigService,
    ) { }

  ngOnInit() {
    this.configService.getConfigListener().subscribe(config => {
      this.listUniverse = config.recipeLabels;
       for(let universe of this.listUniverse){
         this.universeConfig={...this.universeConfig, [universe.slug] : [universe.color]};
       }
    });

    this.authService.getCurrentUniverseListener().subscribe(univers => {
      // console.log('univers', univers);
      this.univers = univers;
    });
    // this.$modeRecipe = this.authService.getPriceModeListener().pipe(map(res => {
    //   return 'Prix ' + res;
    // }));
  }

}
