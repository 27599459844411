import { Pipe, Injectable, PipeTransform } from "@angular/core";

@Pipe({
  name: 'filter'
})
@Injectable()
export class FilterTypeRecipePipe implements PipeTransform {
  transform(items: any[], type: string, accessor = 'cardType'): any[] {
    console.log(items, type, accessor);

    if (!items) return [];
    //return items.filter(u => u[accessor] === type);
    return [...new Set([...items.filter(u => u[accessor] === type), ...items.filter(u => u['type'] === type)])];
  }
}
