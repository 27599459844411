import { Component, OnInit, Input } from '@angular/core';
import { Recipe } from 'src/shared/models/recipe.model';

@Component({
  selector: 'app-single-image',
  templateUrl: './single-image.component.html',
  styleUrls: ['./single-image.component.scss']
})
export class SingleImageComponent implements OnInit {
  @Input() urlImage: string;
  @Input() simplifiedView = false;
  @Input() portrait = false;
  constructor() { }

  ngOnInit() {
  }

}
