/** Find an object in any array/object and return the object if in it */
export function deepSearch(object, key, predicate) {
   // console.log('ibj', object, key, predicate);

    if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) { return object; }

    for (let i = 0; i < Object.keys(object).length; i++) {
        if (typeof object[Object.keys(object)[i]] === 'object') {
            const o = deepSearch(object[Object.keys(object)[i]], key, predicate);
            if (o != null) { return o; }
        }
    }
    return null;
}

// to test monday morning
export function searchRecipe(blocs, recipe) {
    let index;
    let recipeFound;
    blocs.some(element => {
        // maybe test if drink / food, etc
        if (('recettes' in element) && ('categories') in element) {
            if (recipeFound) { return true; }
          recipeFound = searchRecipe(element.categories, recipe);
        }
        if (('recettes' in element) && element.recettes.length > 0 ) {
            if (recipeFound) { return true; }
            index = element.recettes.findIndex(r => r._id === recipe._id);
            if (index >= 0) {
                recipeFound = element.recettes[index];
                return true;
            }
        } else {
            //recipeFound = searchRecipe(element.categories, recipe);
            if (recipeFound) { return true; }
          recipeFound = searchRecipe(element.categories, recipe);
        }
    });
    console.log('FOUND', recipeFound);

    return recipeFound;
}

export function containsObject(obj, list) {
    let i;
    // console.log('liste', list, obj);
    if (list) {
        for (i = 0; i < list.length; i++) {
            if (list[i]._id === obj._id) {
                return true;
            }
        }
    }
    return false;
}
