import { Subscription } from 'rxjs';
import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { Actuality } from 'src/shared/models/actuality.model';
import { ActuService } from '../../../core/services/actualites/actu-service.service';
import { AuthService } from 'src/app/core/authentification/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FilesUrlPipe } from '../../pipes/files-url/files-url.pipe';

@Component({
  selector: 'app-thumbnail-actuality',
  templateUrl: './thumbnail-actuality.component.html',
  styleUrls: ['./thumbnail-actuality.component.scss'],
  providers: [FilesUrlPipe]
})
export class ThumbnailActualityComponent implements OnInit, OnDestroy {
  @Input() actuality: Actuality;

  @ViewChild('spantitle', { static: false }) elementView;

  currentStatus: string;
  currentUrl: string;
  showModal = false;
  viewHeight = 0;
  deleteActuSub: Subscription;
  constructor(private actuService: ActuService, private authService: AuthService, private router: Router, private urlPipe: FilesUrlPipe) { }

  ngOnInit() {
    this.authService.getStatusListener().subscribe(status => {
      if (status) {
        this.currentStatus = status;

      }
  });
    this.currentUrl = this.router.url;
  }

  getFilePath() {
    return 'url(' + this.urlPipe.transform(this.actuality.imagePath) + ')';
  }

  onDelete(_id) {
    this.actuService.onDeleteActuById(_id).subscribe();
  }

  ngOnDestroy() {
    if (this.deleteActuSub) {
      this.deleteActuSub.unsubscribe();
    }
  }

}
