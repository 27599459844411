import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from './core/authentification/auth.service';
import { environment } from 'src/environments/environment';
import { CustomIconService } from './core/services/icons/CustomIconService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showHead = false;
  isAuth = false;

  constructor(private authService: AuthService,
    private router: Router,
    private customIconService: CustomIconService) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if ((event['url'].includes('/connexion') && !event['url'].includes('reset-my-password')) || event['url'] === '/404') {
          this.showHead = false;
        } else {
          this.showHead = true;
        }
      }
      if (event instanceof NavigationEnd && environment.production) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      }
    });
    this.customIconService.init();
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects.includes('/connexion') && !event.urlAfterRedirects.includes('reset-my-password')) {
          this.showHead = false;

        } else {
          this.showHead = true;

        }
      }
    });

    this.isAuth = this.authService.getAuth();

    /**
     * It seems like autoAuthUser doesn't 
     * handle the case when something 
     * bad is happenning
     */
    this.authService.autoAuthUser();
  }
}
