import { Subscription } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Recipe } from '../../../../shared/models/recipe.model';
import { RecipesService } from '../../../core/services/recettes/recipes.service';
import { AuthService } from 'src/app/core/authentification/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-thumbnail-recipe',
  templateUrl: './thumbnail-recipe.component.html',
  styleUrls: ['./thumbnail-recipe.component.scss']
})
export class ThumbnailRecipeComponent implements OnInit, OnDestroy {
  @Input() singleRecipe: Recipe;

  private loadingSub: Subscription;
  deleteRecipeSub: Subscription;
  status = '';
  currentUrl = '';

  showModal = false;
  isLoading: Boolean;

  constructor(private recipeService: RecipesService, private authStatus: AuthService, private router: Router) { }

  ngOnInit() {
    this.authStatus.getStatusListener().subscribe(status => {
      if (status) {
        this.status = status;
      }
  });
    this.currentUrl = this.router.url;
    this.loadingSub = this.recipeService.getLoadingDataListener().subscribe(res => {
      this.isLoading = res;
    });
  }

  onDelete(recipeID) {
    this.deleteRecipeSub = this.recipeService.deleteRecipe(recipeID).subscribe();
  }

  ngOnDestroy() {
    this.loadingSub.unsubscribe();
    if (this.deleteRecipeSub) {
      this.deleteRecipeSub.unsubscribe();
    }
  }

}
