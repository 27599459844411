import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { RecipesService } from '../../../../core/services/recettes/recipes.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-manage-recipes',
  templateUrl: './manage-recipes.component.html',
  styleUrls: ['./manage-recipes.component.scss'],
  animations: [
    // trigger(
    //   'enterAnimation', [
    //     transition(':enter', [
    //       style({transform: 'translateY(-100%)', opacity: 0, }),
    //       animate('800ms ease-in-out', style({transform: 'translateY(0)', opacity: 1}))
    //     ]),
    //     transition(':leave', [
    //       style({transform: 'translateY(0)', opacity: 1}),
    //       animate('800ms ease-in-out', style({transform: 'translateY(-100%)', opacity: 0}))
    //     ])
    //   ]
    // )
  ],
})
export class ManageRecipesComponent implements OnInit, OnDestroy {
  displayAdd = false;
  toggleNumber = false;
  private isAddedSub: Subscription;
  constructor(private toastr: ToastrService, private recipeService: RecipesService) { }

  ngOnInit() {
    this.isAddedSub = this.recipeService.isRecipeAddedUpdateListener().subscribe(res => {
      if (res === true) {
        this.displayAdd = false;
        this.toastr.success('Nouvelle recette ajoutée!', 'IMPORTANT');
      }
    });
  }

  ngOnDestroy() {
    this.isAddedSub.unsubscribe();
  }

}
