import { switchMap, tap, flatMap, take } from 'rxjs/operators';
import { CartesService } from 'src/app/core/services/cartes/cartes.service';
import { RecipesService } from './../../../core/services/recettes/recipes.service';
import { Recipe } from './../../../../shared/models/recipe.model';

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription, of } from 'rxjs';

import { URL_TYPES } from 'src/shared/constants/types';
import UserUniverse from 'src/shared/enum/UserUniverse.enum';
import { AuthService } from 'src/app/core/authentification/auth.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';



@Component({
  selector: 'app-single-recipe',
  templateUrl: './single-recipe.component.html',
  styleUrls: ['./single-recipe.component.scss']
})
export class SingleRecipeComponent implements OnInit, OnDestroy {

  @Input() simplifiedView = false;
  URL_TYPES = URL_TYPES;
  singleRecipe: Recipe;
  recipeID: any;
  recipesSub: Subscription;
  routeSub: Subscription;
  trustedUrl: any = '';
  videoReady = false;
  mode;
  isInCard;
  isFull;
  cardId;
  typeCard;
  universe;
  UNIVERSE = UserUniverse;
  getConstructionCardSub;
  cardState;
  urlApi = environment.baseUrlBack;

  constructor(public route: ActivatedRoute, private recipeService: RecipesService, private cardService: CartesService,
    private authService: AuthService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    /** RECUPERER LE MODE EDITION OU CREATION */
    this.route.params.subscribe(params => {
      const typeURL = params['typeOfCard'];
      this.typeCard = typeURL;
      if (params['carteId']) {
        this.mode = 'edit';
        this.cardId = params['carteId'];
      } else {
        this.mode = 'create';
      }

    });


    // this.recipeService.getAllRecipes().subscribe();

    this.authService.getCurrentUniverseListener().subscribe(universe => {
      if (universe) {
        this.universe = universe;
      }
    });



    this.recipeService.loadingData.next(true);
    /** flatMap operateur lance des requêtes en parallèle */
    this.routeSub = this.route.paramMap.pipe(
      switchMap((paramMap: ParamMap) => {
        this.recipeID = paramMap.get('recipeID');
        return this.recipeService.getRecipeById(this.recipeID);
      }),
      flatMap((singleRecipe) => {
        this.singleRecipe = singleRecipe;
        return this.checkIfFullBloc();
      }),
      flatMap(() => {
        return this.checkIfInCard();
      }))
      .subscribe();
  }

  /** Check if the bloc where is the recipe is full */
  checkIfFullBloc() {
    return this.cardService.cardStateListener().pipe(tap(state => {
      if (state) {
        this.cardState = state;
        this.isFull = this.cardState[this.singleRecipe.cardConfig[0].bloc].isFull;
      }
    }));
  }

  /** Check if the recipe is in the card, obvious */
  checkIfInCard() {
    if (this.simplifiedView) {
      return this.cardService.getConstructionCardListener().pipe(tap(card => {
        if (card) {
          this.isInCard = this.cardService.checkIfElementIsInCard(card, this.singleRecipe);
        } return of(null);
      }));
    } else {
      return of(null);
    }
  }

  onAddRecipe() {
    this.authService.getPriceModeListener().pipe(take(1)).subscribe(priceMode => {
      if (priceMode === 'premium') {
        this.singleRecipe.displayPrice = this.singleRecipe.premiumPrice;
      } else {
        this.singleRecipe.displayPrice = this.singleRecipe.price;
      }
      this.cardService.addElmtInCardRecipes(this.singleRecipe).subscribe();
    });
  }

  cleanURL(oldURL): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
  }

  ngOnDestroy() {
    if (this.recipesSub) {
      this.recipesSub.unsubscribe();
    }
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    if (this.getConstructionCardSub) {
      this.getConstructionCardSub.unsubscribe();
    }

  }
}

