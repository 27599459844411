import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { AuthService } from 'src/app/core/authentification/auth.service';
import { CartesService } from '../../../core/services/cartes/cartes.service';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Card } from 'src/shared/models/card.model';
import { TYPE_HOTEL } from 'src/shared/constants/types';
import {ConfigService} from '../../../core/services/config/config.service';

@Component({
  selector: 'app-mes-cartes-list',
  templateUrl: './mes-cartes-list.component.html',
  styleUrls: ['./mes-cartes-list.component.scss']
})

export class MesCartesListComponent implements OnInit, OnDestroy {
  cards: Card[] = [];
  status;
  config;
  TYPE_HOTEL = TYPE_HOTEL;

  statusSub: Subscription;
  allCardSub: Subscription;
  configSub: Subscription;

  isLoading = true;
  constructor(private cartesServices: CartesService, private authService: AuthService, private dialog: MatDialog, private configService: ConfigService) { }

  ngOnInit() {
    this.statusSub = this.authService.getStatusListener().subscribe(status => {
      if (status) {
        this.status = status;
      }
    });

    this.allCardSub = this.cartesServices.getAllCardsListener().subscribe(cards => {
      if (cards) {
        this.cards = cards;
        this.isLoading = false;
      }
    });

    this.configSub = this.configService.getConfigListener().subscribe( config => {
      if(config) {
        this.config = config;
      }
    });

  }

  openDialog(card) {
    this.dialog.open(DeleteModalComponent, {
      width: '350px',
      data: { card },
    });
  }

  editMyCard(card: Card) {
    this.cartesServices.editMyCard(card);
  }

  generateTooltipMsg(isFull, isCompleteEn) {
    // tslint:disable-next-line: max-line-length
    return (isCompleteEn ? '' : ' Anglais incomplet ') + (!isFull && !isCompleteEn ? ' - ' : '') + (isFull ? '' : ' Recettes manquantes ');
  }

  getCardTypeLabel(cardType) {
    if(cardType && this.config.cardTypes.find(x => x.slug === cardType)) {
      return this.config.cardTypes.find(x => x.slug === cardType).label;
    }
  }

  ngOnDestroy() {
    if (this.statusSub) {
      this.statusSub.unsubscribe();
    }
    if (this.allCardSub) {
      this.allCardSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }
}


@Component({
  selector: 'app-delete-modal',
  templateUrl: 'delete-modal.html',
})

export class DeleteModalComponent {

  constructor(
    public dialogRef: MatDialogRef<DeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private cartesService: CartesService,
    private snackBar: MatSnackBar) { }

  onNoClick() {
    this.dialogRef.close();
  }

  onYesClick() {
    this.cartesService.deleteCardById(this.data.card._id).subscribe(() => {
      this.dialogRef.close();
      this.openSnackBar();
    });
  }

  openSnackBar() {
    this.snackBar.open('Carte supprimée', null, {
      duration: 3000,
    });
  }

}
