import { Component, OnInit, Input } from '@angular/core';
import { Recipe } from 'src/shared/models/recipe.model';

@Component({
  selector: 'app-single-infos',
  templateUrl: './single-infos.component.html',
  styleUrls: ['./single-infos.component.scss']
})
export class SingleInfosComponent implements OnInit {
  @Input() recipe: Recipe;
  @Input() simplifiedView = false;
  constructor() { }

  ngOnInit() {
  }

}
